import React from "react";
import {
    DocumentSection,
    SectionTitle,
    DocumentInput,
    DocumentCount,
    DocumentWrapper
} from "./CounterInput.styled";
import { FaRegFileAlt } from "react-icons/fa";


const CounterInput = ({index, title, value, disabled=false, handleCountChange, style}) => {
    return (
        <DocumentSection style={style}>
            <SectionTitle>
                <FaRegFileAlt size={16} />
                {title}
            </SectionTitle>
            <DocumentWrapper>
                <DocumentInput
                    type="number"
                    value={value}
                    onChange={(e) => handleCountChange(index, e.target.value)}
                    min="0"
                    disabled={disabled}
                />
                <DocumentCount>documents</DocumentCount>
            </DocumentWrapper>
        </DocumentSection>
    )
}

export default CounterInput;