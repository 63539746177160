import styled, { css } from "styled-components";
import DatePicker from 'react-date-picker';

export const StyledDatePicker = styled(DatePicker)`
    width: ${(props) => props.width};
    padding: ${(props) => props.type === "button" && "9px 20px"};
    height: ${(props) => props.height};
    font-size: ${(props) => props.theme.font_delta + 13}px;
    font-style: normal;
    font-weight: 400;
    background-position: 10px center;
    background-repeat: no-repeat;
    background-size: 20px;
    border-radius: 18px;
    border: ${(props) => props.type === "button" && `1px solid ${props.theme.color_theme.inputBorderColor}`};
    background: ${(props) => props.type === "button" && props.theme.color_theme.inputFillColor};
    color: ${(props) => props.theme.color_theme.color};
    &:focus {
      border-color: ${(props) => props.theme.color_theme.primaryColor};
      outline: none;
    }

    .react-calendar {
        ${({ style }) => style && css(style)};
        border: ${(props) => `1px solid ${props.theme.color_theme.inputBorderColor}`};
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
    .react-date-picker__wrapper {
        width: 100%;
        border: 0px;
    }
    .react-date-picker__clear-button {
        opacity: 0.5;
    }

    .react-date-picker__inputGroup__input {
        color: ${(props) => props.theme.color_theme.selectedTheme === "DC_DM_THEME" && props.isSelected ? "#000" : props.theme.color_theme.color};
    }

    .react-date-picker__inputGroup__divider {
        color: ${(props) => props.theme.color_theme.selectedTheme === "DC_DM_THEME" && props.isSelected ? "#000" : props.theme.color_theme.color};
    }

    react-date-picker__inputGroup__input react-date-picker__inputGroup__year {
        width: 28px;
    }

    .react-date-picker__calendar-button__icon {
        opacity: 0.6;
        stroke: ${(props) => props.theme.color_theme.primaryColor};
        &:focus {
            border-color: ${(props) => props.theme.color_theme.primaryColor};
            outline: none;
        }
        &:hover {
            border-color: ${(props) => props.theme.color_theme.primaryColor};
            stroke: ${(props) => props.theme.color_theme.primaryColor};
            outline: none;
        }
    }
`;
