import styled from "styled-components";
import Fonts from "../../../Fonts/Fonts";

export const ModalWrapper = styled.div`
  display: flex;
  width: 556px;
  height: 540px;
  flex-direction: column;
  align-items: flex-start;
`;

export const Container = styled.div`
  min-height: 100vh;
  background-color: #f8fafc;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

export const Card = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  margin-bottom: 2rem;
  text-align: center;
`;


export const Step = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
`;

export const StepTitle = styled.h2`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: ${(props) => props.theme.color_theme.color};
  font-size: ${(props) => props.theme.font_delta + 16}px;
  font-weight: 600;
  margin-bottom: 1rem;
`;


export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 12px;
`;

export const HeadTitle = styled.div`
  ${Fonts.Body3}
  text-transform: uppercase;
`;

export const Title = styled.h5`
  text-transform: uppercase;
`;

export const HeadRight = styled.div`
  display: flex;
`;

export const ProgressText = styled.p`
  color: ${(props) => props.theme.color_theme.color};
  font-size: 1rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  `;


export const SuccessMessage = styled.div`
  color:  ${(props) => props.theme.color_theme.selectedTheme === "DC_DM_THEME" && props.isSelected ? "#000" : props.theme.color_theme.primaryColor};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
  font-weight: 500;
`;


export const Divider = styled.div`
  display: flex;
  align-items: center;
  margin: 0.2rem 0;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #f2f2f2;
  }

  span {
    margin: 0 1rem;
    color: #f2f2f2;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
  }
`;


export const ErrorWrapper = styled.div`
  padding: 10px;
  color: red;
  font-size: ${(props) => props.theme.font_delta + 12}px;
`;


export const StepWrapper = styled.div`
  overflow-Y: auto;
  height: 500px;
`;