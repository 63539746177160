import {StyledDatePicker} from "./DateSelector.styled"
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';


const DateSelector = ({value, setValue, clearIcon=false, type="button", width="584px", height="54px", ...props}) => {
    return (
        <div>
            <StyledDatePicker 
                value={value}
                type={type}
                width={width} 
                height={height}
                clearIcon={clearIcon}
                onChange={setValue} 
                minDate={new Date()}
                style={props.style}
                {...props}
            />
        </div>
    )
}


export default DateSelector;

