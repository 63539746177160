import styled from "styled-components";

export const CheckboxGrid = styled.div`
  display: flex;
  width: 584px;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;



export const CheckboxItem = styled.div`
  background-color: ${(props) => props.theme.color_theme.inputFillColor};
  border-radius: 16px;
  border: ${(props) => `1px solid ${props.theme.color_theme.inputBorderColor}`};
  overflow: hidden;
`;

export const CheckboxHeader = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem;
  cursor: pointer;
  height: 30px;
  transition: all 0.2s;
  background-color: ${props => props.$isExpanded ? props.theme.color_theme.inputFillColor: 'transparent'};

  &:hover {
    background-color: ${props => props.theme.color_theme.inputFillColor};
  }

  input {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.375rem;
    border: 2px solid ${props => props.theme.color_theme.primaryColor};
    cursor: pointer;

    &:checked {
      background-color: ${props => props.theme.color_theme.inputFillColor};
      border-color: ${(props) => props.theme.color_theme.primaryColor};
    }

    &:checked::before {
      content: "✔";
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: ${(props) => props.theme.color_theme.primaryColor};
    }
  }
`;

export const CheckboxChildren = styled('div')`
  padding: ${props => props.$isExpanded ? '0.5rem 0.75rem 0.75rem 3rem' : '0 0.75rem'};
  max-height: ${props => props.$isExpanded ? '500px' : '0'};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ChildCheckbox = styled.label`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 0.375rem;
  transition: all 0.2s;

  &:hover {
    background-color: ${props => props.theme.color_theme.inputFillColor};
  }

  input {
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 0.25rem;
    border: 2px solid ${props => props.theme.color_theme.primaryColor};
    cursor: pointer;
    appearance: none;
    

    &:checked {
      background-color: ${props => props.theme.color_theme.inputFillColor};
      border-color: ${(props) => props.theme.color_theme.primaryColor};
    }

    &:checked::before {
      content: "✔";
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: ${(props) => props.theme.color_theme.primaryColor};
    }
  }
`;