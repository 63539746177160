import React from "react";
import { useLocation, useMatch  } from "react-router-dom";
import {
  DashboardPageWrapper,
  ClientListWrapper,
  GeneratedDocListWrapper,
  AuditListWrapper,
  FullWidthWrapper,
} from "./DashboardPage.styled";
import ClientList from "../../modules/ClientList/ClientList";
import DocumentList from "../../modules/DocumentList/DocumentList";
import GenerateICF from "../../modules/GenerateICF/GenerateICF";
import AuditList from "../../modules/AuditList/AuditList";
import RedactDoc from "../../modules/RedactDoc/RedactDoc";
import PdfViewerPage from "../PdfViewerPage/PdfViewerPage";
import ReviewFormatPage from "../ReviewFormatPage/ReviewFormatPage";
import ReviewGeneratePage from "../ReviewGenerationPage/ReviewGenerationPage";
import ProjectList from "../../modules/ProjectManagement/ProjectList/ProjectList";
import TaskManagementPage from "../TaskManagementPage/TaskManagementPage";
import RFPGenerationPage from "../RFPGenerationPage/RFPGenerationPage";
import GenerateProposal from "../../modules/GenerateRFP/GenerateRFP";

const DashboardPage = () => {
  const location = useLocation();

  const isGeneratePath = location.pathname === "/generate";
  const isStyled = location.pathname === "/format";
  const isRedact = location.pathname === "/redact";
  const isAuditLog = location.pathname === "/logs";
  const isRegulatoryPM = location.pathname === "/project";
  const isProposal = location.pathname === "/proposal";
  const isProposalReview =  location.pathname === "/review/proposal";
  const isPdfReview =  /^\/review\/pdf\/.+/.test(location.pathname);
  const isFormatDocReview = location.pathname === "/review/format/doc";
  const isGenerateDocReview = location.pathname === "/review/generate/doc";
  const isTaskManagement = !!useMatch("/project/:id");

  return (
    <DashboardPageWrapper>
      {isGeneratePath ? (
        <>
          <ClientListWrapper style={{height: 'auto'}}>
             <GenerateICF />
          </ClientListWrapper>
          <GeneratedDocListWrapper>
            <DocumentList />
          </GeneratedDocListWrapper>
        </>
      ) : isAuditLog ? (
        <>
          <AuditListWrapper style={{ height: "auto" }}>
            <AuditList />
          </AuditListWrapper>
        </>
      ) : isStyled ? (
        <>
          <ClientListWrapper style={{height: 'auto'}}>
            <GenerateICF/>
          </ClientListWrapper>
          <GeneratedDocListWrapper>
            <DocumentList />
          </GeneratedDocListWrapper>
        </>
      )  : isRedact ? (
        <>
          <ClientListWrapper>
            <RedactDoc/>
          </ClientListWrapper>
          <GeneratedDocListWrapper>
            <DocumentList />
          </GeneratedDocListWrapper>
        </>
      ) : isRegulatoryPM ? (
        <FullWidthWrapper>
          <ProjectList/>
        </FullWidthWrapper>
      ) : isTaskManagement ? (
        <FullWidthWrapper>
          <TaskManagementPage />
        </FullWidthWrapper>
      ) :isPdfReview ? (
        <PdfViewerPage />
      ) : isFormatDocReview ? (
        <ReviewFormatPage />
      ) : isGenerateDocReview ? (
        <ReviewGeneratePage />
      ) : isProposal ? (
        <GenerateProposal />
      ) : isProposalReview ? (
        <RFPGenerationPage />
      ) : (
        <>
          <ClientListWrapper>
            <ClientList />
          </ClientListWrapper>
          <GeneratedDocListWrapper>
            <DocumentList />
          </GeneratedDocListWrapper>
        </>
      )}
    </DashboardPageWrapper>
  );
};

export default DashboardPage;
