import React, { useEffect, useState } from "react";
import {
    CheckboxGrid,
    CheckboxItem,
    CheckboxHeader,
    CheckboxChildren,
    ChildCheckbox,
} from "./CheckboxTree.styled";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";

const CheckboxTree = ({ initialData, onChange, hideSection }) => {
    const [includedFiles, setIncludedFiles] = useState(initialData);
    const [expandedSections, setExpandedSections] = useState({});
    const [invalidSection, setInvalidSection] = useState({});

    useEffect(() => {
       setInvalidSection(invalidSection)
    }, [hideSection])
    const toggleExpand = (section) => {
        setExpandedSections((prev) => ({
            ...prev,
            [section]: !prev[section],
        }));
    };

    const handleChildCheckbox = (section, child) => {
        setIncludedFiles((prev) => {
            const newState = { ...prev };

            // Toggle child checked state while preserving other properties
            newState[section] = {
                ...prev[section],
                children: {
                    ...prev[section].children,
                    [child]: {
                        ...prev[section].children[child], // Preserve other properties
                        checked: !prev[section].children[child].checked,
                    },
                },
            };

            // Check if all children are checked
            const allChildrenChecked = Object.values(newState[section].children).every(
                (childObj) => childObj.checked
            );
            newState[section].checked = allChildrenChecked;
            return newState;
        });
    };

    const handleParentCheckbox = (section) => {
        setIncludedFiles((prev) => {
            const newState = { ...prev };
            const isChecked = !prev[section].checked;

            // Update parent and all children while preserving properties
            newState[section] = {
                ...prev[section],
                checked: isChecked,
                children: Object.fromEntries(
                    Object.entries(prev[section].children).map(([child, childObj]) => [
                        child,
                        { ...childObj, checked: isChecked }, // Preserve dependencies and other properties
                    ])
                ),
            };
            return newState;
        });
    };

    useEffect(() => {
        if (includedFiles) {
            onChange(includedFiles);
        }
      }, [includedFiles]);


    useEffect(() => {
        setIncludedFiles(initialData)
    }, [initialData])


    function isSectionChecked(section, parentObject) {
        if (parentObject[section]) {
            return parentObject[section]?.checked === true;
        }
        return false
    }

    return (
        <CheckboxGrid>
            {Object.entries(includedFiles).map(([section, { checked, children }]) => {
                const isChecked = isSectionChecked(section, hideSection);
                return !isChecked  && <CheckboxItem key={section}>
                    <CheckboxHeader $isExpanded={expandedSections[section]}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                            {expandedSections[section] ? (
                                <FaChevronDown size={16} onClick={() => toggleExpand(section)} />
                            ) : (
                                <FaChevronRight size={16} onClick={() => toggleExpand(section)} />
                            )}
                            <input
                                type="checkbox"
                                checked={checked}
                                onChange={() => handleParentCheckbox(section)}
                            />
                            {section}
                        </div>
                    </CheckboxHeader>
                    <CheckboxChildren $isExpanded={expandedSections[section]}>
                        {Object.entries(children).map(([childName, { checked: childChecked }]) => (
                            <ChildCheckbox key={childName}>
                                <input
                                    type="checkbox"
                                    checked={childChecked}
                                    onChange={() => handleChildCheckbox(section, childName)}
                                />
                                {childName}
                            </ChildCheckbox>
                        ))}
                    </CheckboxChildren>
                </CheckboxItem>})}
            
        </CheckboxGrid>
    );
};

export default CheckboxTree;
