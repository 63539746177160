import ApiServer from "./ApiServer";
import NCGApiServer from "./NCGApiServer";
import RedactApiServer from "./RedactApiServer";
import ProjectManagementApiServer from "./PmApiServer";
import RFPApiServer from "./RFPApiServer";

export const getDestinationTemplate = (pharmaName) => {
  return ApiServer({
    method: "GET",
    url: `/v1/templates/destination/${pharmaName}`,
  });
};

export const getSourceTemplate = (pharmaName) => {
  return ApiServer({
    method: "GET",
    url: `/v1/templates/source/${pharmaName}`,
  });
};

export const getProgress = (progressId) => {
  return ApiServer({
    method: "GET",
    url: `/poll/${progressId}`,
  });
};

export const generateDocument = (data,{
  pharma_name,
  document_type="Clinical",
  output_file_name,
  is_folder 
}) => {
  return ApiServer({
    method: "POST",
    url: `/v2/document/generate`,
    data: data,
    params: {
      pharma_name,
      document_type,
      output_file_name,
      is_folder 
    },
    timeout: 2000000,
  });
};

export const generateStyledDocument = (data,{
  pharma_name,
  output_file_name,
  input_document_name 
}) => {
  return ApiServer({
    method: "POST",
    url: `/v1/document/style`,
    data: data,
    params: {
      pharma_name,
      output_file_name,
      input_document_name 
    },
    timeout: 2000000,
  });
};

export const getGeneratedDocument = () => {
  return ApiServer({
    method: "GET",
    url: `/v1/generated-files`,
  });
};

export const getEntitlement = () => {
  return ApiServer({
    method: "GET",
    url: `/v1/entitlements`,
  });
};

export const getGeneratedDocumentDownloadUrl = (file_name) => {
  return ApiServer({
    method: "GET",
    url: `/v1/generated-files/${file_name}`
    // params: { file_name },
  });
};

export const getPreviewPDFFile = (template,document_type="Clinical", name) => {
  return ApiServer({
    method: "GET",
    url: `/v1/preview/templates`,
    responseType: "arraybuffer",
    params: { template, document_type,name, mode: "streaming" },
  });
};

export const getPreviewSharepointPDFFile = (pharma_name, document_type="Clinical", file_name,  is_folder=false, template) => {
  return ApiServer({
    method: "GET",
    url: `/v1/preview/source`,
    responseType: "arraybuffer",
    params: { pharma_name, document_type, file_name, is_folder, mode: "streaming", template },
  });
};

export const getClientList = () => {
  return ApiServer({
    method: "GET",
    url: `/list/clients`,
  });
};

export const getMembersList = (pharma_name) => {
  return ApiServer({
    method: "GET",
    url: `/list/clients/users`,
    params: { pharma_name },
  });
};

export const getClientInfo = (pharma_name) => {
  return ApiServer({
    method: "GET",
    url: `/client/info`,
    params: { pharma_name },
  });
};

export const createClient = (pharma_name, data, pharma_details = {}) => {
  return ApiServer({
    method: "POST",
    url: `/client`,
    data: data,
    params: { pharma_name, ...pharma_details },
  });
};

export const addClientUsers = (pharma_name, data) => {
  return ApiServer({
    method: "POST",
    url: `/client/users/add`,
    data: data,
    params: { pharma_name },
  });
};

export const EditClientInfo = ( pharma_name, data, pharma_details = {}) => {
  return ApiServer({
    method: "PUT",
    url: `/clients/info`,
    data: data,
    params: { pharma_name, ...pharma_details },
  });
};

export const deleteClientUsers = (pharma_name, data) => {
  return ApiServer({
    method: "POST",
    url: `/client/users/delete`,
    data: data,
    params: { pharma_name },
  });
};

export const getPharmaClientList = () => {
  return ApiServer({
    method: "GET",
    url: `/pharma_clients`,
  });
};

export const getProtocolDocumentList = (pharma_name) => {
  return ApiServer({
    method: "GET",
    url: `/v1/document/source/list/${pharma_name}`,
  });
};

export const getInputDocumentList = (pharma_name) => {
  return ApiServer({
    method: "GET",
    url: `/v1/document/style/list/${pharma_name}`,
  });
};

export const getConfigurationDocumentList = () => {
  return ApiServer({
    method: "GET",
    url: `/v1/config/styles`,
  });
};

export const searchUser = (value) => {
  return ApiServer({
    method: "GET",
    url: `/v2/client/users/search`,
    params: { value },
  });
};

export const getCsv = (args) => {
  return ApiServer({
    method: "POST",
    url: `/download-csv?args=${args}`,
  });
};

export const getAuditList = (search_query, args,page) => {
  return ApiServer({
    method: "POST",
    url: `/export_audit_logs?search_query=${search_query}&args=${args}&page=${page}&page_size=8`,
  });
};

export const uploadSourceDocument = (pharma_name,data) => {
  return ApiServer({
    method: "POST",
    url: "v1/document/source/upload",
    params: { pharma_name },
    data
  })
}
export const uploadStyleDocument = (pharma_name,data) => {
  return ApiServer({
    method: "POST",
    url: "v1/document/style/upload",
    params: { pharma_name },
    data
  })
}


export const uploadRedactDocument = (pharma_name, source_file_name) => {
  return RedactApiServer({
    method: "POST",
    url: `redact/preview?company_name=${pharma_name}&source_file_name=${source_file_name}`,
    data : [ "ppd", "dosage", "signature", "future_plans", "benefits_and_risks", "stereoisomers" ]
  })
}

export const getPDFList = (pharma_name) => {
  return RedactApiServer({
    method: "GET",
    url: `document/source/list/${pharma_name}`,
  })
}

export const downloadSharepointPdf = (pdfId) => {
  return RedactApiServer({
    method: "GET",
    url: `document/${pdfId}`,
    responseType: "arraybuffer",
  })
}

export const getRedactionReview = (pdfId) => {
  return RedactApiServer({
    method: "POST",
    url: `redact/preview/${pdfId}`
  })
}

export const submitRedactionReview = (pdfId, data) => {
  return RedactApiServer({
    method: "POST",
    url: `redact?document_id=${pdfId}`,
    data: {"redactions": data}
  })
}



export const uploadRedactionInputDoc = (company_name, data) => {
  return RedactApiServer({
    method: "POST",
    url: `document/redact/upload?company_name=${company_name}`,
    data
  })
}


// NCG Requests
export const generateNonClinicalDocument = (data) => {
  
  return NCGApiServer({
    method: "POST",
    url: `/non_clinical/generate`,
    data: data,
    timeout: 2000000,
  });
};


// ========== Project Management APIs ========== \\
export const createProject = (data) => {
  return ProjectManagementApiServer({
    method: "POST",
    url: `/projects/`,
    data: data,
  })
}


export const getProjectEstimate = ({due_date, submission_type="Module 2", source_count_map={}, input_documents=[], workers=0}) => {
  let requestUrl = `/projects/estimate?workers=${workers}&submission_type=${submission_type}&source_count_map=${JSON.stringify(source_count_map)}&input_documents=${JSON.stringify(input_documents)}`;

  if (due_date) {
    requestUrl = `/projects/estimate?due_date=${due_date}&submission_type=${submission_type}&source_count_map=${JSON.stringify(source_count_map)}&input_documents=${JSON.stringify(input_documents)}`;
  }
  
  return ProjectManagementApiServer({
    method: "GET",
    url: requestUrl
  })
}

export const getProjectList = ({query="", skip=0, limit = 100}) => {
  return ProjectManagementApiServer({
    method: "GET",
    url: `/projects/?q=${query}&skip=${skip}&limit=${limit}`,
  })
}

export const getProjectStructure = (submissionType="Module 2") => {
  return ProjectManagementApiServer({
    method: "GET",
    url: `/projects/structure?submission_type=${submissionType}`,
  })
}


export const EditProject = (projectId, data) => {
  delete data?.created_at
  delete data?.updated_at
  return ProjectManagementApiServer({
    method: "PUT",
    url: `/projects/${projectId}`,
    data: data,
  })
}


export const createTask = (data) => {
  return ProjectManagementApiServer({
    method: "POST",
    url: `/tasks/`,
    data: data,
  })
}

export const getTaskList = ({query="", projectId, skip=0, limit=200}) => {
  return ProjectManagementApiServer({
    method: "GET",
    url: `/tasks/?q=${query}&project_id=${projectId}&skip=${skip}&limit=${limit}`,
  })
}


export const deleteTask = (taskId) => {
  return ProjectManagementApiServer({
    method: "DELETE",
    url: `/tasks/${taskId}`,
  })
}


export const EditTask = (taskId, data) => {
  delete data?.created_at
  delete data?.updated_at
  return ProjectManagementApiServer({
    method: "PUT",
    url: `/tasks/${taskId}`,
    data: data,
  })
}



// ========== RFP APIs ========== \\

export const ProcessProposalDocument = (company_name , file_name) => {
  return RFPApiServer({
    method: "POST",
    url: `/document/process_and_fill/${company_name}?source_file_name=${file_name}`,
  })
}

export const uploadRFPInputDoc = (company_name, data) => {
  return RFPApiServer({
    method: "POST",
    url: `/document/rfp/upload?company_name=${company_name}`,
    data
  })
}


export const getRfpSourceList = (pharma_name) => {
  return RFPApiServer({
    method: "GET",
    url: `/document/source/list/${pharma_name}`,
  })
}