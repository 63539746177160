import React, { useEffect, useState, useRef } from "react";
import {
  TableDataRow,
  DataCell,
  TableRowWrapper,
  NumberWrapper,
  DataCellAction,
  LabelCard,
  UserDetailWrapper,
  AssignUserModal,
  CardSpan
} from "./TaskTableRow.styled";
import useKeyPress from "../../../hooks/useKeyPress";
import speechFormat from "../../../helpers/speechFormat";
import { useNavigate } from "react-router-dom";
import TaskDetailSideDrawer from "../TaskDetailSideDrawer/TaskDetailSideDrawer";
import UserIcon from "../../../assets/svgs/UserIcon";
import DateSelector from "../../../components/DateSelector/DateSelector";
import SearchAutocomplete from "../../../components/AutoCompleteSearch/AutoCompleteSearch";


const TaskTableRow = ({
  key,
  row,
  index,
  outerIndex,
  onSelectRow,
  isSelected,
  islast,
  onRowChange,
  currentTab,
  showSubTask,
  expandableIcon,
  showId=true
}) => {
  const [sideDrawerState, setSideDrawerState] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showLabel, setShowLabel] = useState(false);
  const [showAssignUser, setShowAssignUser] = useState(false);
  const [userDetail, setUserDetail] = useState([]);
  const [emailError, setEmailError] = useState(false);
  const navigate = useNavigate();
  const labelRef = useRef(null); // Reference to LabelCard container
  const assignUserRef = useRef(null); // Reference to assignUser container

  const handleRowClick = () => {
    onSelectRow(key);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSideDrawerState = (value) => {
    // if (value && value?.edit) {
    //   setEditmodalOpen(true);
    // }
    setSideDrawerState(false);
  };

  const handleChange = (value, type = "status") => {
    if (type == 'due_date' && value) {
      value = new Date(value.getTime() - value.getTimezoneOffset() * 60000);
    }
    let updateRow = { ...row, [type]: value }
    onRowChange(row?.task_id, updateRow)
    setTimeout(() => setShowLabel(false), 0);
  }

  // useEffect(() => {
  //   console.log("showLabel fdgd ===>", showLabel)
  // }, [showLabel])

  const handleUserInputChange = (e, index, type) => {
    if (type === "email") {
      let data = [...userDetail];
      data[index] = {
        ...data[index],
        email: e.target.value,
        showOptions: true,
      };
      setUserDetail(data);
    }

    if (type === "name") {
      let data = [...userDetail];
      data[index] = { ...data[index], name: e.target.value };
      setUserDetail(data);
    }

  };

  const assignUser = (value) => {
    if (value?.email && value?.name) {
      setUserDetail([...userDetail, { name: value?.name, email: value?.email, role: "owner", showOptions: false }]);
      setTimeout(() => setShowAssignUser(false), 0);
      handleChange(value.email, 'assigned_to')
      setUserDetail([])
    } else if (value?.email) {
      setUserDetail([...userDetail, { email: value?.email, role: "owner", showOptions: false }]);
    } else if (value?.name) {
      setUserDetail([...userDetail, { name: value?.name, role: "owner", showOptions: false }]);
    }
  }

  // Handle click outside to close LabelCard
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (labelRef.current && !labelRef.current.contains(event.target)) {
        setShowLabel(false);
      }
      if (assignUserRef.current && !assignUserRef.current.contains(event.target)) {
        setShowAssignUser(false);
      }
    };

    if (showLabel || showAssignUser) {
        document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showLabel, showAssignUser]);



  const overlayName = "UpdateClientModal";

  useKeyPress("1", [], () => isSelected && setSideDrawerState(true));
  // useKeyPress("2", [], () => isSelected && set(true));
  // useKeyPress("3", [], () => isSelected && setShowAssignUser(true));
  // useKeyPress("4", [], () => isSelected && setShowLabel(true));


  return (
    <>
      <TableRowWrapper isSelected={isSelected} index={index} islast={islast}>
        <TableDataRow
          key={index}
          isSelected={isSelected}
        >
          <DataCell
            isSelected={isSelected}
            width={"10%"}
            style={{ justifyContent: "normal" }}
          >
            {showId && <>
              <div style={{ marginRight: "4px" }}
                data-speech={speechFormat({
                  key: `Alt + ${index + 1}`,
                  action: "select",
                  item: `task with the id ${index + 1}`,
                })}
                tabIndex={0}>{index + 1}.</div>
              <span> [Alt + {index + 1}]</span>
            </>
            }
          </DataCell>
          <DataCell
            width={"30%"}
            style={{ justifyContent: "normal" }}
            tabIndex={0}
            // onClick={() => setSideDrawerState(true)}
            onClick={handleRowClick}
            aria-label="task name"
          >

              <DataCellAction isSelected={isSelected} >
                <span style={{ opacity: "0.8" }}>[1]</span>
              </DataCellAction> 
              <NumberWrapper showSubTask={showSubTask} isSelected={isSelected} tabIndex={0}>
                {expandableIcon}
                <div style={{width: "280px"}}>{row.title}</div>
              </NumberWrapper>

          </DataCell>
          <DataCell width={"15%"} style={{ justifyContent: "normal" }} tabIndex={0} aria-label="Due date">
            <DateSelector
              value={row.due_date}
              isSelected={isSelected}
              width={"120px"}
              style={{position: outerIndex > 3 ? "relative": "fixed" }}
              clearIcon={null}
              type={"normal"}
              setValue={(value) => handleChange(value, "due_date")}
            />
          </DataCell>
          <DataCell
            width={"20%"}
            style={{ justifyContent: "normal" }}
            onClick={() => setShowAssignUser(true)}
          >
            {showAssignUser ?
              <AssignUserModal ref={assignUserRef}>
                <SearchAutocomplete
                  placeholder={"Search User to Assign"}
                  autoFocus={true}
                  handleAddMember={assignUser}
                  searchCommand={{
                    text: "[Alt+S]",
                    key: "S",
                    subkey: ["altKey"],
                  }}
                  isOverlay={true}
                  overlayName={overlayName}
                />
                {/* {userDetail.map((item, index) => {
                  return (
                    <UserDetailWrapper
                      bottomPadding={
                        index == userDetail.length - 1 ? "35" : false
                      }
                      style={{
                        paddingTop: index == 0 ? "0px" : "13px",
                        borderBottom:
                          index == userDetail.length - 1
                            ? "none"
                            : "1px solid #f2f2f2",
                      }}
                    >
                      <>
                        <InputField
                          width={"280"}
                          labelStyle={{
                            position: "absolute",
                            padding: "4px 50px",
                          }}
                          label={`Enter User's Name`}
                          value={item.name}
                          type={"text"}
                          inputStyle={{
                            paddingBottom: "10px",
                            height: "34px"
                          }}
                          onChangefunction={(e) =>
                            handleUserInputChange(e, index, "name")
                          }
                          icon={UserIcon()}
                        />
                        <InputField
                          width={"280"}
                          labelStyle={{
                            position: "absolute",
                            padding: "4px 50px",
                          }}
                          label={`Enter User's Email`}
                          value={item.email}
                          type={"email"}
                          setError={setEmailError}
                          inputStyle={{
                            paddingBottom: "10px",
                            height: "34px"
                          }}
                          onChangefunction={(e) =>
                            handleUserInputChange(e, index, "email")
                          }
                          icon={Emailcon("black")}
                        />
                      </>
                    </UserDetailWrapper>
                  );
                })} */}
              </AssignUserModal>
              : <CardSpan
              >
                <UserIcon />
                {row.assigned_to}
              </CardSpan>}
          </DataCell>
          <DataCell
            width={"10%"}
            style={{ justifyContent: "normal", cursor: "pointer", position: "inherit" }}
            onClick={() => setShowLabel(true)}
          >

            {showLabel ? (
              <LabelCard
                ref={labelRef}
                isSelected={isSelected}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  width: "100px",
                  justifyContent: "center",
                  minWidth: "unset",
                }}
              >
                {["Todo", "Blocked", "In Progress", "Cancelled", "Done"].map((item) => (
                  <span
                    key={item}
                    isSelected={isSelected}
                    onClick={() => handleChange(item)}
                    style={{ fontSize: "12px", cursor: "pointer" }}
                  >
                    {item}
                  </span>
                ))}
              </LabelCard>
            ) : (
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                  fontSize: "12px",
                }}
              >
                {row.status}
              </span>
            )}
          </DataCell>
          <DataCell width={"10%"} style={{ justifyContent: "normal" }}>
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {row?.health || "On Track"}
            </span>
          </DataCell>
        </TableDataRow>
      </TableRowWrapper>
      {sideDrawerState && <TaskDetailSideDrawer
        isOpen={sideDrawerState}
        handleOpen={handleSideDrawerState}
        taskDetails={row}
      />}

    </>
  );
};

export default TaskTableRow;
