import React, { useState, useEffect } from "react";
import {
  BackspaceContainer,
  FormBody,
  FormContainer,
  FormFooter,
  FormTitle,
  Wrapper,
  SelectPharma,
  SelectProtocolDoc,
  LabelTextWrapper,
  ButtonWrapper,
} from "./GenerateICF.styled";
import Button from "../../components/Button/Button";
import BackspaceIcon from "../../assets/svgs/BackspaceIcon";
import InputField from "../../components/InputField/InputField";
import { useLocation, useNavigate } from "react-router-dom";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
import ProtocolModal from "../ProtocolModal/ProtocolModal";
import SuccessICF from "../SuccessICF/SuccessICF";
import { useDispatch } from "react-redux";
import { incrICFCount } from "../../store/slices/UserSlice";
import useKeyPress from "../../hooks/useKeyPress";
import {
  getProtocolDocumentList,
  generateDocument,
  getProgress,
  getConfigurationDocumentList,
  getInputDocumentList,
  generateStyledDocument,
} from "../../apis/ApiServerRequests";
import DownIcon from "../../assets/svgs/DownIcon";
import CompanyBadgeIcon from "../../assets/svgs/CompanyBadgeIcon";
import DocumentIcon from "../../assets/svgs/DocumentIcon";
import SelectIcfIcon from "../../assets/svgs/SelectIcfIcon";
import SelectProtocolIcon from "../../assets/svgs/SelectProtocolIcon";
import SelectProtoDocIcon from "../../assets/svgs/SelectProtoDocIcon";
import SuccessPopup from "../../components/SuccesPopup/SuccessPopup";
import speechFormat from "../../helpers/speechFormat";
import DeleteIcon from "../../assets/svgs/DeleteIcon";
import ConfigurationModal from "../ConfigurationModal/ConfigurationModal";

const GenerateICF = () => {
  const [successData, setSuccessData] = useState(null);
  const [protocolDocLoading, setProtocolDocLoading] = useState(false);
  const [inputDocLoading, setInputDocLoading] = useState(false);
  const [configurationDocLoading, setConfigurationDocLoading] = useState(false);
  const [selectedPharma, setSelectedPharma] = useState("");
  const [selectedConfigurationPharma, setSelectedConfigurationPharma] =
    useState("");
  const [selectedDocumentCategory, setSelectedDocumentCategory] = useState("Clinical")
  const [protocolDocOptions, setProtocolDocOptions] = useState([]);
  const [inputDocOptions, setInputDocOptions] = useState([]);
  const [configurationDocOptions, setConfigurationDocOptions] = useState([]);
  const [isFolder, setIsFolder] = useState(false);
  const [selectedProtocolDoc, setSelectedProtocolDoc] = useState("");
  const [selectedInputDoc, setSelectedInputDoc] = useState("");
  const [prevItemValue, setPrevItemValue] = useState("");
  const [
    selectedSourceTemplateAndDocument,
    setSelectedSourceTemplateAndDocument,
  ] = useState(null);
  const [progress, setProgress] = useState({
    total_mappings: 21,
    mappings_processed: 0,
    estimated_time: 5,
  });
  const location = useLocation();
  const styled = location.pathname.includes("format");
  const [array, setArray] = useState([]);
  const [generateArray, setGenerateArray] = useState([]);

  useEffect(() => {
    setSelectedProtocolDoc("");
    setSelectedProtocolDoc("");
    if (selectedPharma) {
      setProtocolDocLoading(true);
      getProtocolDocumentList(selectedPharma)
        .then(({ data }) => setProtocolDocOptions(data.source_file))
        .finally(() => {
          setProtocolDocLoading(false);
        });
    }

    if (selectedConfigurationPharma) {
      getConfigurationDocumentList()
        .then(({ data }) => {
          setConfigurationDocOptions(data.style);
        })
        .finally(() => {
          setConfigurationDocLoading(false);
        });
    }
    
  }, [selectedPharma, selectedConfigurationPharma]);


  const handleProtocolDocChange = (e) => {
    setSelectedProtocolDoc(e.target.value);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [templateType, setTemplateType] = useState("select_pharmacy");
  const [documentName, setDocumentName] = useState(null);
  const [configurationDocumentName, setConfigurationDocumentName] =
    useState(null);
  const [selectedIcfFile, setSelectedIcfFile] = useState("");
  const [selectedInputFile, setSelectedInputFile] = useState("");
  const [selectedConfigurationFile, setSelectedConfigurationFile] =
    useState("");
  const [
    selectedConfigurationGenerateFile,
    setSelectedConfigurationGenerateFile,
  ] = useState("");
  const [selectedProtocolFile, setSelectedProtocolFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [generated, setGenerated] = useState(false);
  const [preview, setPreview] = useState(true);
  const [pairs, setPairs] = useState(true);
  const [modalTitle, setModalTitle] = useState("");

  const selectDocFile = (name) => {
    if (templateType === "destination_templates") {
      setSelectedIcfFile(name);
    } else if (templateType === "source_templates") {
      setSelectedProtocolFile(name);
    } else if (templateType === "select_pharmacy") {
      if (styled) {
        setSelectedConfigurationPharma(name);
      } else {
        setSelectedPharma(name);
      }
    } else if (templateType === "protocol_Doc") {
      setSelectedProtocolDoc(name);
    } else if (templateType === "input_document") {
      setSelectedInputFile(name);
    }
  };

  const openModal = (templateType) => {
    setModalOpen(true);
    if (templateType === "protocol_Doc") {
      if (selectedPharma) {
        setModalOpen(true);
        setTemplateType(templateType);

        if (templateType === "destination_templates") {
          setModalTitle("Select Destination Template");
          setPrevItemValue(selectedIcfFile);
        } else if (templateType === "source_templates") {
          setModalTitle("Select Source Document");
          // setPrevItemValue(selectedProtocolFile);
        } else if (templateType === "select_pharmacy") {
          setModalTitle("Select the Company");
        } else if (templateType === "protocol_Doc") {
          setModalTitle("Select Source Document");
          // setPrevItemValue(selectedProtocolFile);
        } else if (templateType === "input_document") {
          setModalTitle("Select Input Document");
          setPrevItemValue(selectedInputFile);
        }

        if (templateType === "select_pharmacy") {
          setPreview(false);
        } else {
          setPreview(true);
        }
        if (templateType === "protocol_Doc") {
          setPairs(true);
        } else {
          setPairs(false);
        }
      }
    } else {
      setTemplateType(templateType);

      if (templateType === "destination_templates") {
        setModalTitle("Select Destination Template");
        setPrevItemValue(selectedIcfFile);
      } else if (templateType === "source_templates") {
        setModalTitle("Select Source Document");
        // setPrevItemValue(selectedProtocolFile);
      } else if (templateType === "select_pharmacy") {
        setModalTitle("Select the Company");
      } else if (templateType === "protocol_Doc") {
        setModalTitle("Select Source Document");
        // setPrevItemValue(selectedProtocolFile);
      } else if (templateType === "configuration_Doc") {
        setModalTitle("Select Styling Configuration");
      } else if (templateType === "input_document") {
        setModalTitle("Select Input Document");
        setPrevItemValue(selectedInputFile);
      }

      if (templateType === "select_pharmacy") {
        setPreview(false);
      } else {
        setPreview(true);
      }
      if (templateType === "protocol_Doc") {
        setPairs(true);
      } else {
        setPairs(false);
      }
    }
  };

  const closeModal = (selected = false) => {
    setModalOpen(false);
    if (!selected) {
      if (templateType === "destination_templates") {
        setSelectedIcfFile(prevItemValue);
      } else if (templateType === "input_document") {
        setSelectedInputFile(prevItemValue);
      } else if (templateType === "source_templates") {
        setSelectedProtocolFile(prevItemValue);
      }
    }
    setPrevItemValue("");
  };

  const onBack = () => {
    navigate("/");
  };

  const deletePair = (index) => {
    let copyarray = [...selectedSourceTemplateAndDocument];
    copyarray.splice(index, 1);
    setSelectedSourceTemplateAndDocument(
      copyarray.length > 0 ? copyarray : null
    );
  };

  const handleInputChange = (e) => {
    setDocumentName(e.target.value);
    setConfigurationDocLoading("");
  };

  const handleConfigurableInputChange = (e) => {
    setConfigurationDocumentName(e.target.value);
    setDocumentName("");
  };

  const getDocumentfunction = () => {
    if (styled) {
      let requiredData = {
        pharma_name: selectedConfigurationPharma,
        output_file_name: configurationDocumentName,
        input_document_name: selectedInputFile,
      };
      navigate(`review/format/doc`, { state: { data: array, requiredData: requiredData} })
    } else {
      setLoading(true);
      let requiredData = {
        pharma_name: selectedPharma,
        selectedDocumentCategory: selectedDocumentCategory,
        output_file_name: documentName,
        is_folder: isFolder,
      };
      let body = {
        source_template_type: selectedSourceTemplateAndDocument.map(
          (item) => item.sourceTemplate
        ),
        destination_template_type: [selectedIcfFile],
        source_file_name: selectedSourceTemplateAndDocument.map(
          (item) => item.sourceDocument
        ),
      };
      if (generateArray && generateArray.length > 0) {
        body.styling_configurations_files = generateArray;
      }
      navigate(`review/generate/doc`, { state: { data: body, requiredData: requiredData} })
    }
  };

  const getDocProgress = async (process_id) => {
    try {
      getProgress(process_id)
        .then(({ data }) => {
          setProgress(data);
        })
        .catch(() => {
          alert("Something went wrong. Try again later.");
        });
    } catch (error) {}
  };

  useEffect(() => {
    if (successData) {
      let interval;
      if (
        progress.mappings_processed === progress.total_mappings &&
        progress.filename
      ) {
        setLoading(false);
        setGenerated(true);
      } else {
        interval = setInterval(() => {
          getDocProgress(successData.process_id);
        }, 2000);
      }

      return () => {
        clearInterval(interval);
      };
    }
  }, [successData, progress]);

  useEffect(() => {
    if (generated) {
      setSelectedIcfFile("");
      setSelectedProtocolFile("");
      setSelectedPharma("");
      setSelectedProtocolDoc("");
      setSelectedSourceTemplateAndDocument(null);
    }
  }, [generated]);

  const handleGenerated = (val) => {
    setGenerated(val);
    setDocumentName("");
    setSuccessData(null);
    setProgress({
      total_mappings: 21,
      mappings_processed: 0,
      estimated_time: 5,
    });
  };

  useKeyPress("backspace", [], onBack);
  // useKeyPress("1", ["altKey"], () => );
  useKeyPress("2", ["altKey"], () => openModal("select_pharmacy"));
  useKeyPress("3", ["altKey"], () =>
    styled
      ? openModal("configuration_Doc")
      : selectedPharma
      ? openModal("destination_templates")
      : ""
  );
  // useKeyPress("4", ["altKey"], () => openModal("source_templates"));
  // useKeyPress("4", ["altKey"], () => openModal("protocol_Doc"));
  useKeyPress("4", ["altKey"], () =>
    styled
      ? selectedConfigurationPharma
        ? openModal("input_document")
        : ""
      : selectedPharma
      ? openModal("protocol_Doc")
      : ""
  );
  useKeyPress("5", ["altKey"], () =>
    !styled ? openModal("configuration_Doc") : ""
  );
  useKeyPress("G", ["altKey"], getDocumentfunction);
  useKeyPress("1", ["altKey", "ctrlKey"], () => deletePair(0));
  useKeyPress("2", ["altKey", "ctrlKey"], () => deletePair(1));
  useKeyPress("3", ["altKey", "ctrlKey"], () => deletePair(2));
  useKeyPress("4", ["altKey", "ctrlKey"], () => deletePair(3));
  useKeyPress("5", ["altKey", "ctrlKey"], () => deletePair(4));
  useKeyPress("6", ["altKey", "ctrlKey"], () => deletePair(5));
  useKeyPress("7", ["altKey", "ctrlKey"], () => deletePair(6));
  useKeyPress("8", ["altKey", "ctrlKey"], () => deletePair(7));
  useKeyPress("9", ["altKey", "ctrlKey"], () => deletePair(8));
  useKeyPress("10", ["altKey", "ctrlKey"], () => deletePair(9));
  useKeyPress("backspace", [], () => onBack());

  return (
    <Wrapper>
      <BackspaceContainer>
        <Button
          icon={<BackspaceIcon />}
          type={"ghost"}
          accessKeyText={"[BACKSPACE]"}
          onClick={onBack}
          iconStyle={{ marginRight: "4px" }}
          data-speech={speechFormat({
            key: "Backspace",
            action: "go back",
            item: "to Dashboard",
          })}
        />
      </BackspaceContainer>
      {generated && !loading ? (
        styled ? (
          <SuccessICF
            handleGenerated={handleGenerated}
            docTitle={documentName}
            docName={progress?.filename}
            styled={true}
            totalMapping={progress?.total_mappings}
            resolvedMapping={progress?.mappings_processed}
          ></SuccessICF>
        ) : (
          <SuccessICF
            handleGenerated={handleGenerated}
            docTitle={documentName}
            docName={progress?.filename}
            totalMapping={progress?.total_mappings}
            resolvedMapping={progress?.mappings_processed}
          ></SuccessICF>
        )
      ) : (
        // <SuccessICF
        //   handleGenerated={handleGenerated}
        //   docTitle={documentName}
        //   docName={progress?.filename}
        //   totalMapping={progress?.total_mappings}
        //   resolvedMapping={progress?.mappings_processed}
        // ></SuccessICF>
        <FormContainer>
          {styled ? (
            <FormTitle>FORMAT DOCUMENT</FormTitle>
          ) : (
            <FormTitle>GENERATE DOCUMENT</FormTitle>
          )}

          <FormBody
            style={
              styled
                ? {
                    alignItems: "center",
                  }
                : {
                    alignItems: !selectedSourceTemplateAndDocument
                      ? "center"
                      : "inherit",
                  }
            }
          >
            {styled ? (
              <InputField
                width={"420"}
                focus={true}
                wrapperStyle={{ marginBottom: "24px" }}
                onChangefunction={handleConfigurableInputChange}
                labelStyle={{
                  position: "absolute",
                  padding: "4px 50px",
                }}
                label={"Enter the name of doc"}
                value={configurationDocumentName}
                inputStyle={{ paddingBottom: "10px", height: "34px" }}
                icon={DocumentIcon()}
                inputCommand={{
                  text: "[Alt + 1]",
                  key: "1",
                  subkey: ["altKey"],
                }}
                data-speech={speechFormat({
                  key: "",
                  action: "",
                  item: "Enter the name of doc",
                })}
              />
            ) : (
              <InputField
                width={"420"}
                focus={true}
                wrapperStyle={{ marginBottom: "24px" }}
                onChangefunction={handleInputChange}
                labelStyle={{
                  position: "absolute",
                  padding: "4px 50px",
                }}
                label={"Enter the name of doc"}
                value={documentName}
                inputStyle={{ paddingBottom: "10px", height: "34px" }}
                icon={DocumentIcon()}
                inputCommand={{
                  text: "[Alt + 1]",
                  key: "1",
                  subkey: ["altKey"],
                }}
                data-speech={speechFormat({
                  key: "",
                  action: "",
                  item: "Enter the name of doc",
                })}
              />
            )}

            {styled ? (
              <>
                <InputField
                  width={"420"}
                  readonly={true}
                  wrapperStyle={{ marginBottom: "24px" }}
                  onClick={() => openModal("select_pharmacy")}
                  onChangefunction={() => openModal("select_pharmacy")}
                  rightIcon={DownIcon()}
                  inputCommand={{
                    text: "[Alt + 2]",
                    key: "2",
                    subkey: ["altKey"],
                  }}
                  value={selectedConfigurationPharma}
                  labelStyle={{
                    position: "absolute",
                    padding: "4px 50px",
                  }}
                  label={"Select the Company"}
                  inputStyle={{ paddingBottom: "10px", height: "34px" }}
                  icon={CompanyBadgeIcon()}
                  data-speech={speechFormat({
                    key: "Alt + 2",
                    action: "",
                    item: "Select the Company",
                  })}
                />
              </>
            ) : (
              <>
                <InputField
                  width={"420"}
                  readonly={true}
                  wrapperStyle={{ marginBottom: "24px" }}
                  onClick={() => openModal("select_pharmacy")}
                  onChangefunction={() => openModal("select_pharmacy")}
                  rightIcon={DownIcon()}
                  inputCommand={{
                    text: "[Alt + 2]",
                    key: "2",
                    subkey: ["altKey"],
                  }}
                  value={selectedPharma}
                  labelStyle={{
                    position: "absolute",
                    padding: "4px 50px",
                  }}
                  label={"Select the Company"}
                  inputStyle={{ paddingBottom: "10px", height: "34px" }}
                  icon={CompanyBadgeIcon()}
                  data-speech={speechFormat({
                    key: "Alt + 2",
                    action: "",
                    item: "Select the Company",
                  })}
                />
              </>
            )}

            {styled ? (
              <>
                {selectedConfigurationFile ? (
                  <InputField
                    width={"420"}
                    readonly={true}
                    wrapperStyle={{ marginBottom: "24px" }}
                    onClick={() => openModal("configuration_Doc")}
                    onChangefunction={() => openModal("configuration_Doc")}
                    value={selectedConfigurationFile}
                    labelStyle={{
                      position: "absolute",
                      padding: "4px 50px",
                    }}
                    label={"Select Styling Configuration"}
                    inputStyle={{ paddingBottom: "10px", height: "34px" }}
                    icon={SelectIcfIcon()}
                    data-speech={speechFormat({
                      key: "Alt + 3",
                      action: "",
                      item: "Select Styling Configuration",
                    })}
                  />
                ) : (
                  <InputField
                    width={"420"}
                    readonly={true}
                    wrapperStyle={{ marginBottom: "24px" }}
                    onClick={() => openModal("configuration_Doc")}
                    onChangefunction={() => openModal("configuration_Doc")}
                    value={selectedConfigurationFile}
                    rightIcon={DownIcon()}
                    inputCommand={{
                      text: "[Alt + 3]",
                      key: "3",
                      subkey: ["altKey"],
                    }}
                    labelStyle={{
                      position: "absolute",
                      padding: "4px 50px",
                    }}
                    label={"Select Styling Configuration"}
                    inputStyle={{ paddingBottom: "10px", height: "34px" }}
                    icon={SelectIcfIcon()}
                    data-speech={speechFormat({
                      key: "Alt + 3",
                      action: "",
                      item: "Select Styling Configuration",
                    })}
                  />
                )}

                {/* <LabelTextWrapper>
                  Source document will be taken from Selected Client's
                  Sharepoint
                </LabelTextWrapper> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                  }}
                >
                  <InputField
                    width={"420"}
                    readonly={true}
                    disabled={!selectedConfigurationPharma ? true : false}
                    wrapperStyle={{ marginBottom: "24px" }}
                    onClick={() => openModal("input_document")}
                    onChangefunction={() => openModal("input_document")}
                    value={selectedInputFile}
                    rightIcon={DownIcon()}
                    inputCommand={{
                      text: "[Alt + 4]",
                      key: "4",
                      subkey: ["altKey"],
                    }}
                    labelStyle={{
                      position: "absolute",
                      padding: "4px 50px",
                    }}
                    label={"Select Input Document"}
                    inputStyle={{
                      paddingBottom: "10px",
                      height: "34px",
                      cursor: selectedConfigurationPharma ? "" : "not-allowed",
                    }}
                    icon={SelectIcfIcon()}
                    data-speech={speechFormat({
                      key: "Alt + 4",
                      action: "",
                      item: "Select Input Document",
                    })}
                  />
                </div>
              </>
            ) : (
              <>
                <InputField
                  width={"420"}
                  readonly={true}
                  disabled={!selectedPharma}
                  wrapperStyle={{ marginBottom: "24px" }}
                  onClick={() => openModal("destination_templates")}
                  onChangefunction={() => openModal("destination_templates")}
                  value={selectedIcfFile}
                  rightIcon={DownIcon()}
                  inputCommand={{
                    text: "[Alt + 3]",
                    key: "3",
                    subkey: ["altKey"],
                  }}
                  labelStyle={{
                    position: "absolute",
                    padding: "4px 50px",
                  }}
                  label={"Select Destination Template"}
                  inputStyle={{
                    paddingBottom: "10px",
                    height: "34px",
                    cursor: selectedPharma ? "" : "not-allowed",
                  }}
                  icon={SelectIcfIcon()}
                  data-speech={speechFormat({
                    key: "Alt + 3",
                    action: "",
                    item: "Select Destination Template",
                  })}
                />
                <LabelTextWrapper>
                  Source document will be taken from Selected Client's
                  Sharepoint
                </LabelTextWrapper>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                  }}
                >
                  {!selectedSourceTemplateAndDocument ? (
                    <InputField
                      width={"420"}
                      readonly={true}
                      disabled={!selectedPharma}
                      wrapperStyle={{ marginBottom: "24px" }}
                      onClick={() => openModal("protocol_Doc")}
                      onChangefunction={() => openModal("protocol_Doc")}
                      rightIcon={DownIcon()}
                      inputCommand={{
                        text: "[Alt + 4]",
                        key: "4",
                        subkey: ["altKey"],
                      }}
                      value={selectedProtocolDoc}
                      labelStyle={{
                        position: "absolute",
                        padding: "4px 50px",
                      }}
                      label={"Select Source Document"}
                      icon={SelectProtoDocIcon()}
                      inputStyle={{
                        paddingBottom: "10px",
                        height: "34px",
                        cursor: selectedPharma ? "" : "not-allowed",
                      }}
                      data-speech={speechFormat({
                        key: "Alt + 4",
                        action: "",
                        item: "Select Source Document",
                      })}
                    />
                  ) : (
                    selectedSourceTemplateAndDocument.map((item, index) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "24px",
                        }}
                      >
                        <InputField
                          width={"420"}
                          readonly={true}
                          disabled={!selectedPharma}
                          wrapperStyle={{ marginBottom: "24px" }}
                          value={`${item.sourceTemplate} & ${item.sourceDocument}`}
                          labelStyle={{
                            position: "absolute",
                            padding: "4px 50px",
                          }}
                          label={`Pair ${index + 1}`}
                          icon={SelectProtoDocIcon()}
                          onClick={() => openModal("protocol_Doc")}
                          inputStyle={{
                            paddingBottom: "10px",
                            height: "34px",
                            cursor: selectedPharma ? "" : "not-allowed",
                          }}
                          data-speech={speechFormat({
                            key: "Alt + 4",
                            action: "",
                            item: "Select Source Document",
                          })}
                        />
                        <div style={{ marginBottom: "24px" }}>
                          <Button
                            type="ghost"
                            accessKeyText={`[Ctrl + Alt + ${index + 1}]`}
                            buttonStyle={{ padding: "0px 0px", height: "26px" }}
                            icon={<DeleteIcon />}
                            onClick={() => deletePair(index)}
                            data-speech={speechFormat({
                              key: "",
                              action: "",
                              item: "",
                            })}
                          />
                        </div>
                      </div>
                    ))
                  )}
                </div>
                <LabelTextWrapper>
                  Select styling configuraion file [Optional]
                </LabelTextWrapper>
                {selectedConfigurationGenerateFile ? (
                  <InputField
                    width={"420"}
                    readonly={true}
                    wrapperStyle={{ marginBottom: "24px" }}
                    onClick={() => openModal("configuration_Doc")}
                    onChangefunction={() => openModal("configuration_Doc")}
                    value={selectedConfigurationGenerateFile}
                    labelStyle={{
                      position: "absolute",
                      padding: "4px 50px",
                    }}
                    label={"Select Styling Configuration"}
                    inputStyle={{ paddingBottom: "10px", height: "34px" }}
                    icon={SelectIcfIcon()}
                    data-speech={speechFormat({
                      key: "Alt + 5",
                      action: "",
                      item: "Select Styling Configuration",
                    })}
                  />
                ) : (
                  <InputField
                    width={"420"}
                    readonly={true}
                    wrapperStyle={{ marginBottom: "24px" }}
                    onClick={() => openModal("configuration_Doc")}
                    onChangefunction={() => openModal("configuration_Doc")}
                    value={selectedConfigurationGenerateFile}
                    rightIcon={DownIcon()}
                    inputCommand={{
                      text: "[Alt + 5]",
                      key: "3",
                      subkey: ["altKey"],
                    }}
                    labelStyle={{
                      position: "absolute",
                      padding: "4px 50px",
                    }}
                    label={"Select Styling Configuration"}
                    inputStyle={{ paddingBottom: "10px", height: "34px" }}
                    icon={SelectIcfIcon()}
                    data-speech={speechFormat({
                      key: "Alt + 5",
                      action: "",
                      item: "Select Styling Configuration",
                    })}
                  />
                )}
              </>
            )}
          </FormBody>
          {styled ? (
            <FormFooter>
              {!loading ? (
                <Button
                  disabled={
                    !selectedConfigurationPharma ||
                    !configurationDocumentName ||
                    !selectedInputFile
                  }
                  text={styled ? "Format Doc" : "Generate Doc"}
                  type={"brand"}
                  accessKeyText={"[Alt + G]"}
                  onClick={getDocumentfunction}
                  data-speech={speechFormat({
                    key: "Alt + G",
                    action: "generate",
                    item: "Doc",
                  })}
                />
              ) : (
                <Button text={"Generating..."} type={"secondary"} disabled />
              )}
            </FormFooter>
          ) : (
            <FormFooter>
              {!loading ? (
                <Button
                  disabled={
                    !selectedPharma ||
                    !documentName ||
                    !selectedIcfFile ||
                    !selectedProtocolFile ||
                    !selectedProtocolDoc
                  }
                  text={styled ? "Format Doc" : "Generate Doc"}
                  type={"brand"}
                  accessKeyText={"[Alt + G]"}
                  onClick={getDocumentfunction}
                  data-speech={speechFormat({
                    key: "Alt + G",
                    action: "generate",
                    item: "Doc",
                  })}
                />
              ) : (
                <Button text={"Generating..."} type={"secondary"} disabled />
              )}
            </FormFooter>
          )}
        </FormContainer>
      )}
      {loading && (
        <SuccessPopup
          msg_processing={"Generating Doc, Please wait..."}
          onClose={() => {}}
          loading={true}
          progress={progress}
          styled={styled}
        />
      )}
      {modalOpen && (
        <ModalWrapper
          isOpen={modalOpen}
          onClose={closeModal}
          overlayName={templateType}
        >
          {templateType === "configuration_Doc" ? (
            <ConfigurationModal
              overlayName={templateType}
              onClose={closeModal}
              templateType={templateType}
              // setTemplateType={setTemplateType}
              // selectDocFile={selectDocFile}
              setIsFolder={setIsFolder}
              enablePreview={preview}
              // pairComponents={pairs}
              title={modalTitle}
              protocolDocOptions={configurationDocOptions}
              protocolDocLoading={configurationDocLoading}
              // pharmaName={selectedPharma}
              // selectedSourceTemplateAndDocument={
              //   selectedSourceTemplateAndDocument
              // }
              // setSelectedSourceTemplateAndDocument={
              //   setSelectedSourceTemplateAndDocument
              // }
              setSelectedConfigurationFile={
                styled
                  ? setSelectedConfigurationFile
                  : setSelectedConfigurationGenerateFile
              }
              array={styled ? array : generateArray}
              setArray={styled ? setArray : setGenerateArray}
            />
          ) : (
            <ProtocolModal
              overlayName={templateType}
              onClose={closeModal}
              templateType={templateType}
              setTemplateType={setTemplateType}
              selectDocFile={selectDocFile}
              setIsFolder={setIsFolder}
              enablePreview={preview}
              pairComponents={pairs}
              title={modalTitle}
              protocolDocOptions={styled ? inputDocOptions : protocolDocOptions}
              protocolDocLoading={styled ? inputDocLoading : protocolDocLoading}
              pharmaName={styled ? selectedConfigurationPharma : selectedPharma}
              selectedSourceTemplateAndDocument={
                selectedSourceTemplateAndDocument
              }
              setSelectedSourceTemplateAndDocument={
                setSelectedSourceTemplateAndDocument
              }
              setSelectedInputFile = {setSelectedInputFile}
              setSelectedProtocolDoc = {setSelectedProtocolDoc}
              selectedDocumentCategory={selectedDocumentCategory} 
              setSelectedDocumentCategory={setSelectedDocumentCategory}
            />
          )}
        </ModalWrapper>
      )}
    </Wrapper>
  );
};

export default GenerateICF;
