import styled from "styled-components";
import Fonts from "../../../Fonts/Fonts";

export const ModalWrapper = styled.div`
  display: flex;
  width: 956px;
  height: 520px;
  flex-direction: column;
  align-items: flex-start;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 12px;
  margin-bottom: 20px;
`;

export const HeadTitle = styled.div`
  ${Fonts.Body3}
  text-transform: uppercase;
`;

export const Title = styled.h5`
  text-transform: uppercase;
`;
export const HeadRight = styled.div`
  display: flex;
`;


export const ExtraWrapper = styled.div`
  width: 100%;
  padding-top: 15px;
`;

export const ErrorWrapper = styled.div`
  padding: 10px;
  color: red;
  font-size: ${(props) => props.theme.font_delta + 12}px;
`;


export const Step = styled.div`
  padding-top: 0.5rem;
  border-radius: 0.5rem;
`;

export const StepTitle = styled.h2`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: ${(props) => props.theme.color_theme.color};
  font-size: ${(props) => props.theme.font_delta + 16}px;
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const ProgressText = styled.p`
  color: ${(props) => props.theme.color_theme.color};
  font-size: 1rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;



export const DocumentSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;
  padding: 0.75rem;
  width: 558px;
  border-radius: 16px;
  border: ${(props) => `1px solid ${props.theme.color_theme.inputBorderColor}`};
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${(props) => props.theme.color_theme.color};
  font-weight: 500;
  min-width: 120px;
`;

export const DocumentInput = styled.input`
  width: 45px;
  padding: 9px 20px;
  font-size: ${(props) => props.theme.font_delta + 13}px;
  font-style: normal;
  font-weight: 400;
  height: ${({ height }) => (height ? height : "")};
  padding-left: ${({ hasIcon }) => (hasIcon ? "50px" : "20px")};
  background-position: ${({ hasIcon }) =>
    hasIcon ? "10px center" : "2px center"};
  background-repeat: no-repeat;
  background-size: ${({ hasIcon }) => (hasIcon ? "20px" : "auto")};
  border-radius: 16px;
  border: ${(props) => `1px solid ${props.theme.color_theme.inputBorderColor}`};
  background: ${(props) => props.theme.color_theme.inputFillColor};
  color: ${(props) => props.theme.color_theme.color};
  &:focus {
    border-color: ${(props) => props.theme.color_theme.primaryColor}; /* Change to your desired focus color */
    outline: none;
  }
`;

export const DocumentCount = styled.span`
  color: ${(props) => props.theme.color_theme.color};
  font-size: 0.875rem;
  margin-left: auto;
`;

export const DocumentWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 20px;
`;