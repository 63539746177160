import styled from "styled-components";

export const MainWrapper = styled.div`
  display: flex;
  width: 100vw;
  margin-top: 10px;
  flex-direction: column;
`;

export const BackspaceContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height : 36px;
`;

export const HeaderTitleWrapper = styled.div`
  width: 100%; 
  display: flex;
  justify-content: space-between;
`;

export const HeaderTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
`;

export const EditorWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  display: ${props => (props.sideAlign ? 'flex' : 'block')};
`;

export const DocWrapper = styled.div`
  flex: 1; 
  // height: ${props => (props.sideAlign ? '620px' : 'auto')};
  width:  ${props => (props.sideAlign ? '100%' : '50%')};
`;

