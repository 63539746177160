import React, { useState, useEffect, useRef } from 'react';
import {
  ModalWrapper,
  Card,
  Step,
  StepTitle,
  HeaderWrapper,
  HeadTitle,
  HeadRight,
  ProgressText,
  SuccessMessage,
  Divider,
  ErrorWrapper,
  StepWrapper
} from "./EstimateProjectModal.styled";
import { RiGroupLine } from "react-icons/ri";
import { BiLoaderAlt } from "react-icons/bi";
import Button from "../../../components/Button/Button";
import { LuClock4, LuCircleCheckBig, LuCalendar } from "react-icons/lu";
import { IoDocumentsOutline } from "react-icons/io5";
import useKeyPress from '../../../hooks/useKeyPress';
import { useLocation  } from "react-router-dom";
import speechFormat from "../../../helpers/speechFormat";
import InputField from '../../../components/InputField/InputField';
import DateSelector from '../../../components/DateSelector/DateSelector';
import { EditProject, getProjectEstimate } from '../../../apis/ApiServerRequests';
import { incrTaskCount } from '../../../store/slices/TaskSlice';
import { useDispatch } from "react-redux";
import CounterInput from '../../../components/CounterInput/CounterInput';


function EstimateProjectModal({ onClose, onSuccess, overlayName }) {
  const location = useLocation();
  const { project_id, due_date, workers, project } = location.state || {};
  const [writers, setWriters] = useState(workers || null);
  const [loading, setLoading] = useState(false);
  const [dueDate, setDueDate] = useState(ensureZAtEnd(due_date) || new Date());
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentProgress, setCurrentProgress] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const [respData, setRespData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [calculateButton, setCalculateButton] = useState(false);
  const [documentCounts, setDocumentCounts] = useState([
    {value: project?.source_count_map["Pharmacology Reports"] || 0, title: "Pharmacology Reports"},
    {value: project?.source_count_map["Pharmacokinetics Reports"]  || 0, title: "Pharmacokinetics Reports"},
    {value: project?.source_count_map["Toxicology Reports"] || 0, title: "Toxicology Reports"}
  ]);
  const bottomRef = useRef(null);
  const dispatch = useDispatch();

  const handleWritersChange = (e) => {
    const value = parseInt(e.target.value);
    setWriters(value);
    setIsComplete(false);
    setCalculateButton(false);
  };

  function ensureZAtEnd(dateTimeString) {
    if (dateTimeString.endsWith('Z')) {
        return dateTimeString; 
    } else {
        return dateTimeString + 'Z'; 
    }
  }


  const handleDateChange = (value) => {
    setErrorMessage('')
    setIsComplete(false);
    if (value) {
      const localDate = new Date(value.getTime() - value.getTimezoneOffset() * 60000);
      setDueDate(localDate);
    }
    setCalculateButton(false);
  }

  const handleUpdateProject = async () => {
    onSuccess({ loading: true });
    project['workers'] = respData?.required_workers
    project['due_date'] = respData?.estimated_due_date

    project["source_count_map"] = convertSourceCountMap(documentCounts)

    project["input_documents"] = project?.input_documents

    try {
      await EditProject(project_id, project)
      dispatch(incrTaskCount(1))
    } catch (error) {
      console.log("Edit project has error ==>", error)
    }
    onSuccess({ loading: false });
  };


  useEffect(() => {
    if (writers && dueDate) {
      setCalculateButton(false);
    }

  }, [writers, dueDate])

  const handleDocumentCountChange = (index, value) => {
    const numValue = parseInt(value) || 0;
    setDocumentCounts(prevCounts => {
      const newCounts = [...prevCounts];
      newCounts[index] = { ...newCounts[index], value: numValue };
      return newCounts;
    });
  };

  function convertSourceCountMap(input) {
    const source_count_map = {};
    input.forEach(item => {
        source_count_map[item.title] = item.value;
    });
    return source_count_map;
  }

  const updateTimeline = async () => {
    
    setCalculateButton(true)
    const steps = [
      'Analyzing project parameters...',
      'Calculating timelines based on writers and due date...',
      'Assigning tasks and balancing workload...',
      'Finalizing changes for submission...'
    ];
    
    function formatDate(inputDate) {
      try {
        if (inputDate.includes("T")) {
          return inputDate.split("T")[0] + "T00:00:00Z";
        }
      } catch (err) {
        console.log("date ==>", inputDate)
      }
      const date = new Date(inputDate);
      const isoString = date.toISOString().split("T")[0] + "T00:00:00Z";
      return isoString
    }

    let data = {
      due_date: formatDate(dueDate)
    }
    if (workers !== writers) {
      data = {
        due_date: null,
        workers: writers,
      }
    }

    data["source_count_map"] = convertSourceCountMap(documentCounts)

    data["input_documents"] = project?.input_documents
    data["submission_type"] = project?.type



    setIsUpdating(true);
    setIsComplete(false);
    setCurrentProgress(steps[0]); // Show only the first step initially

    try {
      let estimate = await getProjectEstimate(data); // Wait for API response

      // Show remaining steps one by one after the API response
      for (let i = 1; i < steps.length; i++) {
        setCurrentProgress(steps[i]);
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      if (estimate) setRespData(estimate.data);
    } catch (error) {
      console.error("Error fetching project estimate:", error);
      setCurrentProgress("Something went wrong!")
    }
    setIsUpdating(false);
    setIsComplete(true);
  };

  useEffect(() => {
    if (isComplete && bottomRef.current) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [isComplete])

  const handleValidation = () => {
    let valid = true;

    if (errorMessage) {
      valid = false;
    }
  
    if (!respData) valid = false;

    return valid;
  };


  useEffect(() => {
    if (project) {
      setWriters(project?.workers);
      setDueDate(ensureZAtEnd(project?.due_date));
      setDocumentCounts([
        { value: project?.source_count_map?.["Pharmacology Reports"] || 0, title: "Pharmacology Reports" },
        { value: project?.source_count_map?.["Pharmacokinetics Reports"] || 0, title: "Pharmacokinetics Reports" },
        { value: project?.source_count_map?.["Toxicology Reports"] || 0, title: "Toxicology Reports" }
      ]);
    }
  }, [project]);


  useEffect(() => {
    if (respData && new Date(dueDate).getTime() < new Date(respData?.estimated_due_date).getTime()) {
      setErrorMessage('* The submission date cannot be earlier than the estimated date.');
    } else {
      setErrorMessage('');
    }
  }, [respData]);

  useKeyPress("Escape", [], onClose, true, overlayName, true);

  useKeyPress("Enter", [], handleUpdateProject, true, overlayName, true);

  useKeyPress("T", [], updateTimeline, true, overlayName, true);

  return (
    <ModalWrapper>
      <Card>
        <HeaderWrapper>
          <HeadTitle>{"PROJECT MANAGEMENT"}</HeadTitle>
          <HeadRight>
            <Button
              type={"ghost"}
              text={"Cancel"}
              onClick={onClose}
              accessKeyText={"[Esc]"}
              data-speech={speechFormat({
                key: "Escape",
                action: "close",
                item: "Project Management Dialog",
              })}
            />
            <Button
              type={"secondary"}
              text={loading ? "Saving..." : "Update"}
              onClick={handleUpdateProject}
              accessKeyText={"[Enter]"}
              disabled={!handleValidation() || loading}
              data-speech={speechFormat({
                key: "Enter",
                action: "create",
                item: "Update Project",
              })}
            />
          </HeadRight>
        </HeaderWrapper>

        <StepWrapper>
          <Step>
            <StepTitle>
              <IoDocumentsOutline size={24} />
              Manage Documents
            </StepTitle>
            {documentCounts.map((item, index) => 
              <CounterInput 
                style={{height: "25px"}}
                key={`${item.title}-${index}`} 
                index={index}
                title={item.title} 
                value={item.value} 
                disabled={item.value ? false : true}
                handleCountChange={handleDocumentCountChange}
              />
            )}
          </Step>

          <Step>
            <StepTitle>
              <RiGroupLine size={24} />
              Estimate Medical Writers
            </StepTitle>
            <InputField
              type="number"
              value={writers}
              onChangefunction={handleWritersChange}
              height={"25px"}
            />
          </Step>

          <Divider><span>Or</span></Divider>

          <Step>
            <StepTitle>
              <LuCalendar size={24} />
              Estimate Submission Date
            </StepTitle>
            <DateSelector
              setValue={handleDateChange}
              value={dueDate}
              width={"524px"}
              height={"45px"}
            />
            {dueDate && errorMessage && <ErrorWrapper><span>{errorMessage}</span></ErrorWrapper>}
          </Step>

          <Step style={{paddingTop: "0px"}}>
            <Button
              type={"secondary"}
              text={"Calculate Timeline"}
              onClick={updateTimeline}
              accessKeyText={"[T]"}
              disabled={calculateButton || loading}
              data-speech={speechFormat({
                key: "Enter",
                action: "create",
                item: "Calculate Timeline",
              })}
            />
            {isUpdating && (
              <ProgressText>
                <BiLoaderAlt size={16} style={{ animation: "spin 1.5s linear infinite" }} />
                {currentProgress}
                <style>
                  {`
                    @keyframes spin {
                      from { transform: rotate(0deg); }
                      to { transform: rotate(360deg); }
                    }
                  `}
                </style>
              </ProgressText>
            )}
            {isComplete && (
              <SuccessMessage>
                <LuCircleCheckBig size={16} />
                Timeline successfully updated!
              </SuccessMessage>
            )}


          </Step>
          {isComplete && <Step style={{paddingTop: "0px"}}>
            <StepTitle>
              <LuClock4 size={24} />
              Estimated Completion
            </StepTitle>
            {respData && <p>
              With {respData?.required_workers || 2} medical writers, the estimated submission date is{' '}
              <strong>{new Date(respData?.estimated_due_date || new Date()).toLocaleDateString()}</strong>.
            </p>}
          </Step>}
          <div ref={bottomRef} ></div>
        </StepWrapper>
      </Card>
    </ModalWrapper>
  );
}

export default EstimateProjectModal;