import React, { useEffect, useState } from "react";
import {
    CheckboxGrid,
    CheckboxItem,
    CheckboxHeader,
    CheckboxChildren,
    ChildCheckbox,
    DocumentCount,
    DocumentInput,
    DocumentInputWrapper,
    SectionTitle
} from "./CheckboxTreeCounter.styled";
import isEqual from "lodash/isEqual";
import { FaChevronDown, FaChevronRight, FaRegFileAlt } from "react-icons/fa";
import CounterInput from "../CounterInput/CounterInput";


const CheckboxTreeCounter = ({ initialData, defaultChildrenData, onChange }) => {
    const [includedFiles, setIncludedFiles] = useState(initialData);
    const [expandedSections, setExpandedSections] = useState({});

    
    const [defaultChildren, setDefaultChildren] = useState(null);
    
    useEffect(() => {
        if (defaultChildrenData) setDefaultChildren(defaultChildrenData)
    }, [defaultChildrenData])


    const toggleExpand = (section) => {
        setExpandedSections((prev) => ({
            ...prev,
            [section]: !prev[section],
        }));
    };

    const handleDocumentCountChange = (index, value) => {
        const numValue = parseInt(value) || 0;
        setDefaultChildren(prevCounts => {
            const newCounts = [...prevCounts];
            newCounts[index] = { ...newCounts[index], value: numValue };
            return newCounts;
        });
    };

    const handleParentCheckbox = (section) => {
        setIncludedFiles((prev) => {
            const newState = { ...prev };
            const isChecked = !prev[section].checked;

            // Update parent and all children while preserving properties
            newState[section] = {
                ...prev[section],
                checked: isChecked
            };
            return newState;
        });
    };

    useEffect(() => {
        if (!isEqual(initialData, includedFiles)) {
            onChange(includedFiles, defaultChildren);
        } else {
            onChange(null, defaultChildren)
        }
    }, [includedFiles, defaultChildren]);


    useEffect(() => {
        if (!isEqual(initialData, includedFiles)) {
            setIncludedFiles(initialData)
        }
    }, [initialData])

    return (
        <CheckboxGrid>
            {includedFiles && Object.entries(includedFiles).map(([section, { checked }]) => (
                <CheckboxItem key={section}>
                    <CheckboxHeader $isExpanded={expandedSections[section]}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', width: '100%' }}>
                            {expandedSections[section] ? (
                                <FaChevronDown size={16} onClick={() => toggleExpand(section)} />
                            ) : (
                                <FaChevronRight size={16} onClick={() => toggleExpand(section)} />
                            )}
                            <input
                                type="checkbox"
                                checked={checked}
                                onChange={() => handleParentCheckbox(section)}
                            />
                            <SectionTitle>
                                {section}
                            </SectionTitle>
                        </div>
                    </CheckboxHeader>
                    <CheckboxChildren $isExpanded={expandedSections[section]}>
                        {defaultChildren && section.startsWith("2.6") && defaultChildren?.map((item, index) =>
                            <ChildCheckbox>
                                <CounterInput
                                    key={`${item.title}-${index}`}
                                    index={index}
                                    style={{
                                        width: "558px",
                                        padding: "0px",
                                        border: "0px",
                                        margin: "0.2rem 0"
                                    }}
                                    disabled={checked}
                                    title={item.title}
                                    value={item.value}
                                    handleCountChange={handleDocumentCountChange}
                                />
                            </ChildCheckbox>
                        )}
                    </CheckboxChildren>
                </CheckboxItem>
            ))}
        </CheckboxGrid>
    )
}


export default CheckboxTreeCounter;