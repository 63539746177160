import React, { useEffect, useState, useRef } from "react";
import {
  ModalWrapper,
  HeaderWrapper,
  HeadTitle,
  HeadRight,
  Title,
  ExtraWrapper,
  ErrorWrapper,
  Step,
  StepTitle,
  ProgressText,
} from "./AddProjectModal.styled";
import { LuClock4 } from "react-icons/lu";
import { BiLoaderAlt } from "react-icons/bi";
import Button from "../../../components/Button/Button";
import InputField from "../../../components/InputField/InputField";
import { useDispatch } from "react-redux";
import DropDownNew from "../../../components/Dropdown/Dropdown";
import CompanyBadgeIcon from "../../../assets/svgs/CompanyBadgeIcon";
import useKeyPress from "../../../hooks/useKeyPress";
import speechFormat from "../../../helpers/speechFormat";
import DateSelector from "../../../components/DateSelector/DateSelector";
import EntitlementIcon from "../../../assets/svgs/EntitlementIcon";
import { createProject, getProjectEstimate, getProjectStructure } from "../../../apis/ApiServerRequests";
import { useSelector } from "react-redux";
import Autocomplete from "../../../components/Autocomplete/Autocomplete";
import { incrProjectCount } from "../../../store/slices/ProjectSlice";
import CounterInput from "../../../components/CounterInput/CounterInput";
import CheckboxTree from "../../../components/CheckboxTree/CheckboxTree";
import CheckboxTreeCounter from "../../../components/CheckboxTreeCounter/CheckboxTreeCounter";


const AddProjectModal = ({ onClose, onSuccess, overlayName }) => {
  const dispatch = useDispatch();
  const [pharmaName, setPharmaName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [submissionDate, setSubmissionDate] = useState(new Date());
  const [submissionType, setSubmissionType] = useState("Module 2");
  const [loading, setLoading] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");
  const client_list = useSelector((state) => state?.client?.client_list || []);
  const [pharmaCompanyList, setPharmaCompanyList] = useState([]);

  const [estimateDate, setEstimateDate] = useState(null);
  const [isComplete, setIsComplete] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [respData, setRespData] = useState(null);
  const [currentProgress, setCurrentProgress] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [documentCounts, setDocumentCounts] = useState([
    {value: 20, title: "Pharmacology Reports"},
    {value: 10, title: "Pharmacokinetics Reports"},
    {value: 15, title: "Toxicology Reports"}
  ]);

  const [sourceDocument, setSourceDocument] = useState(null)
  const [initialValue, setInitialValue] = useState(null)
  const [includedFiles, setIncludedFiles] = useState(null)


  function updateCheckedStatus(section, sourceDoc) {
    if (!sourceDoc) return; // Prevent errors if sourceDoc is null

    // Create a deep copy of sourceDocument to avoid direct mutation
    const updatedSourceDocument = JSON.parse(JSON.stringify(sourceDoc)); // Use structuredClone for deep copy
    // Iterate over section and update the checked status if key exists
    Object.entries(section).forEach(([key, value]) => {
      if (key.startsWith("2.6")) {
        setDocumentCounts([
          {value: value["checked"] ? 0: 5, title: "Pharmacology Reports"},
          {value: value["checked"] ? 0: 5, title: "Pharmacokinetics Reports"},
          {value: value["checked"] ? 0: 5, title: "Toxicology Reports"}
        ])
      }
      if (key in updatedSourceDocument) {
          updatedSourceDocument[key].checked = value.checked; // Update checked value
      }
    });

    // Update state with new object reference
    setSourceDocument(updatedSourceDocument);
  }

  const handleDocumentCountChange = (section, documentCounts) => {
    if (section) {
      updateCheckedStatus(section,sourceDocument)
    } else {
      setDocumentCounts(documentCounts)
    }
  };

  function extractSections(input) {
    const output = {};
  
    Object.keys(input).forEach(key => {
      const dependencies = input[key].dependencies;
  
      Object.keys(dependencies).forEach(depKey => {
        const depType = dependencies[depKey].type;
  
        if (depType === "Section") {
          // If the key starts with "2.6", group it under "2.6"
          if (depKey.startsWith("2.6")) {
            output["2.6 Nonclinical Written and Tabulated Summaries"] = { checked: false };
          } else {
            // Otherwise, keep the same name
            output[depKey] = { checked: false };
          }
        }
      });
    });
  
    return output;
  }

  function extractDependencies(data) {
    let dependenciesMap = new Map();

    function traverse(obj) {
        if (obj.dependencies && Array.isArray(obj.dependencies)) {
            obj.dependencies.forEach(dep => {
                dependenciesMap.set(dep, (dependenciesMap.get(dep) || 0) + 5);
            });
        }
        if (obj.children && typeof obj.children === "object") {
            Object.values(obj.children).forEach(child => traverse(child));
        }
    }

    Object.values(data).forEach(section => traverse(section));

    return Array.from(dependenciesMap.entries()).map(([title, value]) => ({ value, title }));
  }


  function convertSourceCountMap(input) {
    const source_count_map = {};
    input.forEach(item => {
        source_count_map[item.title] = item.value;
    });
    return source_count_map;
  }


  function convertData(data) {
    let result = {};

    for (let key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            let value = data[key];

            // Initialize the structure for each key in the result
            let newEntry = {
                checked: false,
                dependencies: value.dependencies || [], // Preserve existing dependencies
                children: {} // Ensure children always exist
            };

            // If the entry has child objects, process them recursively
            let childKeys = Object.keys(value).filter(k => k !== "dependencies");
            if (childKeys.length > 0) {
              let childrenData = {};
              childKeys.forEach(childKey => {
                  childrenData[childKey] = value[childKey];
              });
              newEntry.children = convertData(childrenData);
            }

            result[key] = newEntry;
        }
    }

    return result;
}

  function getCheckedItems(data) {
    let checkedItems = [];
  
    for (const [key, value] of Object.entries(data)) {
      if (value.checked) {
        checkedItems.push(key);
      }
  
      if (value.children) {
        checkedItems.push(...getCheckedItems(value.children));
      }
    }
  
    return checkedItems;
  }

  const bottomRef = useRef(null);


  useEffect(() => {
    if (client_list) {
      setPharmaCompanyList(client_list.map((item, index) => ({id: index, name: item["pharmaName"]})))

      getProjectStructure(submissionType).then(({data}) => {

        let modifiedData = convertData(data["task_structure"])
        let sourceSection = extractSections(data["dependencies_structure"])
        setSourceDocument(sourceSection)
        setInitialValue(modifiedData)
        setIncludedFiles(modifiedData)
        let sourceDoc = extractDependencies(modifiedData)
        setDocumentCounts(sourceDoc)
      }).catch((err) => {
        console.log("error in fetching project structure", err)
      })
    }

  }, [client_list, submissionType])


  const handleInputChange = (e, callback) => {
    callback(e.target.value);
  };

  const handleValidation = () => {
    let valid = true;

    if (!pharmaName) {
      valid = false;
    }

    if (!projectName) {
      valid = false;
    }

    if (!submissionType) {
      valid = false;
    }

    if (submissionDate.getTime() <= (new Date()).getTime() ) {
      valid = false;
    }

    if (errorMessage) {
      valid = false;
    }

    if (estimateDate && submissionDate.getTime() < estimateDate.getTime()) {
      valid = false;
    }

    return valid;
  };

  const updateTimeline = async (date) => {
    const steps = [
      'Initializing project scope...',
      'Calculating timelines based on submission date...',
      'Assigning tasks and balancing workload...',
      'Finalizing changes for submission...'
    ];

    let data = {
      due_date: new Date(date).toISOString().split("T")[0] + "T00:00:00Z",
      source_count_map: convertSourceCountMap(documentCounts),
      input_documents: getCheckedItems(includedFiles),
      submission_type: submissionType
    }
    setIsUpdating(true);
    setIsComplete(false);
    setCurrentProgress(steps[0]); // Show only the first step initially

    try {
      let estimate = await getProjectEstimate(data); // Wait for API response
      if (estimate) setRespData(estimate.data);
      // Show remaining steps one by one after the API response
      for (let i = 1; i < steps.length; i++) {
        setCurrentProgress(steps[i]);
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      setEstimateDate(new Date(estimate.data.estimated_due_date))
    } catch (error) {
      console.error("Error fetching project estimate:", error);
      setCurrentProgress("Something went wrong!")
    }
    setIsUpdating(false);
    setIsComplete(true);
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (submissionDate.getTime() > (new Date()).getTime() ) {
      updateTimeline(submissionDate)
    }
  }, [submissionDate])


  const handleSubmissionDate = (value) => {
    if (value) {
      const localDate = new Date(value.getTime() - value.getTimezoneOffset() * 60000);
      setSubmissionDate(localDate)
    }
    setErrorMessage('')
  }


  const handleAddProject = () => {
    if (!handleValidation()) return;

    let data = {
      project_name: projectName,
      company_name: pharmaName,
      due_date: respData?.estimated_due_date || submissionDate,
      type: submissionType,
      source_count_map: convertSourceCountMap(documentCounts),
      input_documents: getCheckedItems(includedFiles)
    };
    createProject(data)
      .then(() => {
        setLoading(true);
        dispatch(incrProjectCount(1));
        onSuccess({ loading: true });
      })
      .finally(() => {
        setLoading(false);
        onSuccess({ loading: false });
      })
  };

  const handleCheckboxChange = (newState) => {
    setIncludedFiles(newState);
  };


  const handleCalendarOpen = () => {
    // Scroll to the bottom when the calendar is opened
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };


  useEffect(() => {
    if (estimateDate && submissionDate.getTime() < estimateDate.getTime()) {
      setErrorMessage('* The submission date cannot be earlier than the estimated date.');
    } else {
      setErrorMessage('');
    }
  }, [estimateDate]);

  useEffect(() => {
    if (initialValue) {
      // Find invalid dependencies (those with value === 0)
      const invalidDependencies = new Set(
        documentCounts.filter((doc) => doc.value === 0).map((doc) => doc.title)
      );
      // If there are no invalid dependencies, reset to initial state
      if (invalidDependencies.size === 0) {
        setIncludedFiles(initialValue);
        return;
      }
  
      // Filter top-level sections and their children based on invalid dependencies
      const filteredFiles = Object.fromEntries(
        Object.entries(initialValue)
          .filter(
            ([_, sectionValue]) =>
              sectionValue?.dependencies?.length === 0 || // Keep if no dependencies
              sectionValue?.dependencies?.some((dep) => !invalidDependencies.has(dep)) // Keep if at least one valid dependency
          )
          .map(([sectionKey, sectionValue]) => [
            sectionKey,
            {
              ...sectionValue,
              children: Object.fromEntries(
                Object.entries(sectionValue.children).filter(
                  ([_, childValue]) =>
                    childValue?.dependencies?.length === 0 || // Keep if no dependencies
                    childValue?.dependencies?.some((dep) => !invalidDependencies.has(dep)) // Keep if at least one valid dependency
                )
              ),
            },
          ])
      );
  
  
      setIncludedFiles(filteredFiles);
    }
  }, [documentCounts, initialValue]);
  



  useKeyPress("Escape", [], onClose, true, overlayName, true);
 
  useKeyPress("Enter", [], handleAddProject, true, overlayName, true);

  return (
    <ModalWrapper>
      <HeaderWrapper>
        <HeadTitle>{"PROJECT TIMELINE CREATION"}</HeadTitle>
        <HeadRight>
          <Button
            type={"ghost"}
            text={"Cancel"}
            onClick={onClose}
            accessKeyText={"[Esc]"}
            data-speech={speechFormat({
              key: "Escape",
              action: "close",
              item: "Project Creation Dialog",
            })}
          />
          <Button
            type={"secondary"}
            text={loading ? "Saving..." : "Create"}
            onClick={handleAddProject}
            accessKeyText={"[Enter]"}
            disabled={!handleValidation() || loading}
            data-speech={speechFormat({
              key: "Enter",
              action: "create",
              item: "New Project",
            })}
          />
        </HeadRight>
      </HeaderWrapper>
      <div
        style={{
          height: "100%",
          width: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
          paddingBottom: "70px",
          paddingLeft: "4px"
        }}
      >
        <InputField
          focus={true}
          label={"Enter Project Name"}
          labelStyle={{
            position: "absolute",
            padding: "4px 50px",
          }}
          inputStyle={{ paddingBottom: "10px", height: "34px" }}
          width={"584"}
          type={"text"}
          value={projectName}
          onChangefunction={(e) => handleInputChange(e, setProjectName)}
          icon={CompanyBadgeIcon()}
        />
        <ExtraWrapper>
          <Title>Company Name</Title>
          <div style={{ height: "52px" }}>
            <Autocomplete
              items={pharmaCompanyList}
              placeholder={"Search Company to Add"}
              onSelected={setPharmaName}
            />
          </div>
        </ExtraWrapper>
        
        <ExtraWrapper >
          <Title>Select Submission Type</Title>
          <div 
            style={{ width: 584 }}
          >
            <DropDownNew
              width={"584"}
              label={`Select submission type`}
              labelStyle={{
                position: "absolute",
                padding: "4px 50px",
              }}
              options={["IND", "Module 1", "Module 2", "Module 5" ]}
              defaultText={`Module 2`}
              onChangefunction={(value) =>
                setSubmissionType(value)
              }
              icon={EntitlementIcon()}
            />
          </div>
        </ExtraWrapper>
        {<ExtraWrapper style={{paddingTop: "0px"}}>
          <Title>Manage Source Documents</Title>
            <CheckboxTreeCounter 
              initialData={sourceDocument} 
              defaultChildrenData={documentCounts} 
              onChange={handleDocumentCountChange} 
            />
        </ExtraWrapper>}

        <ExtraWrapper>
          <Title>Manage Generated Documents</Title>
          {initialValue && includedFiles && <CheckboxTree 
            initialData={includedFiles}
            hideSection={sourceDocument}
            onChange={handleCheckboxChange}
          />}
        </ExtraWrapper>
        <ExtraWrapper>
          <Title>Select Submission Date</Title>
          <DateSelector 
            setValue={handleSubmissionDate} 
            value={submissionDate}
            onCalendarOpen={handleCalendarOpen}  
          />
          {estimateDate && errorMessage && <ErrorWrapper><span>{errorMessage}</span></ErrorWrapper>}
          {isUpdating && (
            <ProgressText>
              <BiLoaderAlt size={16} style={{ animation: "spin 1.5s linear infinite" }} />
              {currentProgress}
              <style>
                {`
                  @keyframes spin {
                    from { transform: rotate(0deg); }
                    to { transform: rotate(360deg); }
                  }
                `}
              </style>
            </ProgressText>
          )}
          {isComplete && <Step>
            <StepTitle>
              <LuClock4 size={24} />
              Estimated Completion
            </StepTitle>
            {isComplete && <p>
              With {respData?.required_workers || 2} medical writers, the estimated submission date is{' '}
              <strong>{new Date(respData?.estimated_due_date || new Date()).toLocaleDateString()}</strong>.
            </p>}
          </Step>}
        </ExtraWrapper>
        <div ref={bottomRef} ></div>
      </div>
    </ModalWrapper>
  );
};

export default AddProjectModal;
