import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  IconWrapper,
  InputFieldWrapper,
  Label,
  StyledInput,
  FileLabel,
  FileBrowseButton,
  ErrorWrapper,
  RightIconsWrapper,
  MicIconWrapper,
  SearchIconWrapper,
  ExtraTextWrapper,
  RightIconWrapper,
  DropDownWrapper,
  OptionMenu,
  OptionRow,
  OptionText,
} from "./InputField.styled";
import MicIcon from "../../assets/svgs/MicIcon";
import SearchIcon from "../../assets/svgs/SearchIcon";
import validateEmail from "../../helpers/validateEmail";
import useKeyPress from "../../hooks/useKeyPress";
import truncateText from "../../helpers/truncateText";

const InputField = forwardRef(
  (
    {
      label,
      icon,
      labelStyle,
      wrapperStyle,
      inputStyle,
      iconStyle,
      width,
      height,
      type,
      value,
      rightIcon,
      righticons,
      setSelectedFile,
      acceptedFileType,
      onChangefunction,
      setError,
      searchCommand,
      voiceCommand,
      isOverlay,
      overlayName,
      handleSearchError,
      inputCommand,
      readonly,
      showOptions,
      options,
      focus,
      thres,
      fileUploadAccessKey = {key: "U", subKey: ["altKey"], text : "[ Alt + U ]"},
      ...props
    },
    ref
  ) => {
    const inputRef = useRef(null);
    const fileInputRef = useRef(null);

    const handleFileChange = (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        setSelectedFile(selectedFile);
      }
    };

    const handleButtonClick = () => {
      fileInputRef?.current?.click();
    };
    const [errorMessage, setErrorMessage] = useState("");

    const [isListening, setIsListening] = useState(false);

    function setSpeechRecognition() {
      if (
        "SpeechRecognition" in window ||
        "webkitSpeechRecognition" in window
      ) {
        const recognition = new (window.SpeechRecognition ||
          window.webkitSpeechRecognition)();

        recognition.onstart = () => {
          setIsListening(true);
        };

        recognition.onresult = (event) => {
          const result = event.results[0][0].transcript;
          onChangefunction(null, result);
        };

        recognition.onend = () => {
          setIsListening(false);
        };

        recognition.onerror = (event) => {
          console.log("Speech recognition error:", event.error);
        };

        return recognition;
      } else {
        let msg = "Web Speech API is not supported in this browser.";
        handleSearchError(msg);
        console.log(msg);
      }
    }

    const handleSpeechRecognition = () => {
      setSpeechRecognition()?.start();
    };

    const handleSearch = () => {
      inputRef?.current?.focus(); // Focus on the input field
    };

    const handleEscSearch = () => {
      inputRef?.current?.blur(); // Focus on the input field
    };

    useEffect(() => {
      if (type === "email" && value) {
        if (!validateEmail(value)) {
          setErrorMessage(
            "Enter the email address in the format someone@example.com"
          );
          setError(true);
        } else {
          setError(false);
          setErrorMessage("");
        }
      } else {
        setErrorMessage("");
      }
    }, [value]);

    useKeyPress(
      searchCommand?.key,
      searchCommand?.subkey,
      handleSearch,
      isOverlay,
      overlayName
    );

    useKeyPress(
      voiceCommand?.key,
      voiceCommand?.subkey,
      handleSpeechRecognition,
      isOverlay,
      overlayName
    );

    useKeyPress(
      inputCommand?.key,
      inputCommand?.subkey,
      handleSearch,
      isOverlay,
      overlayName
    );

    useKeyPress(
      fileUploadAccessKey?.key,
      fileUploadAccessKey?.subKey,
      handleButtonClick,
      isOverlay,
      overlayName
    )

    useKeyPress("Escape", [], handleEscSearch, isOverlay, overlayName, true);

    function highlightMatchingChars(string1, string2) {
      if (string1 && string2.includes(string1)) {
        const result = [];
        const maxLength = string2.length;

        for (let i = 0; i < maxLength; i++) {
          const char1 = string1[i];
          const char2 = string2[i];

          if (char1 === char2) {
            result.push(<strong key={i}>{char1.toLowerCase()}</strong>);
          } else {
            result.push(char1);
            result.push(char2);
          }
        }
        return result;
      }
    }

    return (
      <InputFieldWrapper
        style={{
          justifyContent: value?.length > 0 ? "normal" : "center",
          ...wrapperStyle,
        }}
        width={width}
        thres={thres}
      >
        {label && (
          <Label
            style={{
              pointerEvents: "none",
              fontSize: value?.length > 0 ? "10px" : "14px",
              ...labelStyle,
            }}
          >
            {label}
          </Label>
        )}
        <StyledInput
          height={height}
          ref={inputRef}
          type={type === "file" ? "text": type}
          autoFocus={focus}
          {...props}
          style={inputStyle}
          hasIcon={!!icon}
          onChange={onChangefunction}
          value={type === "file" ? truncateText(props?.placeholder, 38) : truncateText(value, 38)}
          disabled={type === "file"}
          readonly={readonly}
        />
        <ErrorWrapper messageLength={errorMessage.length}>
          {errorMessage}
        </ErrorWrapper>
        {type == "file" && (
          <>
            <input
              type="file"
              ref={fileInputRef}
              accept={acceptedFileType}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <FileLabel>
              <FileBrowseButton
                onClick={handleButtonClick}
              >{`Browse ${fileUploadAccessKey?.text}`}</FileBrowseButton>
            </FileLabel>
          </>
        )}
        {icon && <IconWrapper style={{ ...iconStyle }}>{icon}</IconWrapper>}

        {rightIcon && (
          <RightIconWrapper style={{ pointerEvents: "none", ...iconStyle }}>
            {rightIcon}
            {inputCommand ? (
              <ExtraTextWrapper>{inputCommand.text}</ExtraTextWrapper>
            ) : null}
          </RightIconWrapper>
        )}

        {inputCommand && !rightIcon ? (
          <RightIconWrapper style={{ pointerEvents: "none", ...iconStyle }}>
            <ExtraTextWrapper>{inputCommand.text}</ExtraTextWrapper>
          </RightIconWrapper>
        ) : null}
        <SearchIconWrapper>
          {righticons && (
            <>
              <RightIconsWrapper onClick={handleSpeechRecognition}>
                <MicIconWrapper isListening={isListening}>
                  {MicIcon()}
                </MicIconWrapper>
                <ExtraTextWrapper>{voiceCommand?.text}</ExtraTextWrapper>
              </RightIconsWrapper>
              <RightIconsWrapper onClick={handleSearch}>
                {SearchIcon()}
                <ExtraTextWrapper>{searchCommand?.text}</ExtraTextWrapper>
              </RightIconsWrapper>
            </>
          )}
        </SearchIconWrapper>

        {showOptions && options?.length > 0 && (
          <DropDownWrapper>
            <OptionMenu role="menu">
              {options
                .filter((o) => o.includes(value))
                .map((option, key) => (
                  <OptionRow
                    onClick={(e) => {
                      onChangefunction({
                        target: { value: option, selected: true },
                      });
                      setError(false);
                    }}
                  >
                    <OptionText>
                      {highlightMatchingChars(value, option)}
                    </OptionText>
                  </OptionRow>
                ))}
            </OptionMenu>
          </DropDownWrapper>
        )}
      </InputFieldWrapper>
    );
  }
);

InputField.propTypes = {};

InputField.defaultProps = {};

export default InputField;
