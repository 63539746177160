import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    BackspaceContainer,
    Wrapper,
    FormBody,
    FormContainer,
    FormTitle,
    FormFooter
} from "./GenerateRFP.styled";
import Button from "../../components/Button/Button";
import BackspaceIcon from "../../assets/svgs/BackspaceIcon";
import speechFormat from "../../helpers/speechFormat";
import DownIcon from "../../assets/svgs/DownIcon";
import SelectIcfIcon from "../../assets/svgs/SelectIcfIcon";
import CompanyBadgeIcon from "../../assets/svgs/CompanyBadgeIcon";
import SuccessPopup from "../../components/SuccesPopup/SuccessPopup";
import useKeyPress from "../../hooks/useKeyPress";
import InputField from "../../components/InputField/InputField";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
import ProtocolModal from "../ProtocolModal/ProtocolModal";
import { uploadRFPInputDoc } from "../../apis/ApiServerRequests";

const GenerateProposal = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [inputDocument, setInputDocument] = useState(null);
    const [selectedPDFDoc, setSelectedPDFDoc] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [generated, setGenerated] = useState(false);
    const [selectedPharma, setSelectedPharma] = useState("");
    const [templateType, setTemplateType] = useState("rfp_file");


    const onBack = () => {
        navigate("/");
    };

    const handleSubmit = async () => {
        setLoading(true);
        const formData = new FormData();
        
        if (inputDocument) {
            formData.append("file", inputDocument);
            try {
                await uploadRFPInputDoc(selectedPharma, formData)
            } catch (error) {
                console.log("error uploading rfp source ==>", error)
            }
        }
        let requiredData = {
            pharma_name: selectedPharma,
            input_document_name: selectedPDFDoc,
        };
        navigate(`/review/proposal`, { state: { data: requiredData} })
    };

    const handleInputFile = async (template_type, file) => {
        if (template_type === "rfp_file") {
            setInputDocument(file)
        }
    }

    const openModal = (templateType) => {
        setTemplateType(templateType)
        setModalOpen(true)
    }

    const closeModal = () => {
        setModalOpen(false)
    }

    const selectDocFile = (filename) => {

        if (templateType === "select_pharmacy") {
            setSelectedPharma(filename);
        } else {
            setSelectedPDFDoc(filename)
        }
    }

    useKeyPress("G", ["altKey"], handleSubmit);
    useKeyPress("1", ["altKey"], () => openModal("select_pharmacy"));
    useKeyPress("2", ["altKey"], () => selectedPharma.length && openModal("rfp_file"));
    useKeyPress("backspace", [], () => onBack());

    return (
        <Wrapper>
            <BackspaceContainer>
                <Button
                    icon={<BackspaceIcon />}
                    type={"ghost"}
                    accessKeyText={"[BACKSPACE]"}
                    onClick={onBack}
                    iconStyle={{ marginRight: "4px" }}
                    data-speech={speechFormat({
                        key: "Backspace",
                        action: "go back",
                        item: "to Dashboard",
                    })}
                />
            </BackspaceContainer>

            {!generated && <FormContainer>
                <FormTitle>Generate Proposal</FormTitle>

                <FormBody>
                    <InputField
                        width={"420"}
                        readonly={true}
                        onClick={() => openModal("select_pharmacy")}
                        onChangefunction={() => openModal("select_pharmacy")}
                        rightIcon={DownIcon()}
                        inputCommand={{
                            text: "[Alt + 1]",
                            key: "1",
                            subkey: ["altKey"],
                        }}
                        value={selectedPharma}
                        labelStyle={{
                            position: "absolute",
                            padding: "4px 50px",
                        }}
                        label={"Select the Company"}
                        inputStyle={{ paddingBottom: "10px", height: "34px" }}
                        icon={CompanyBadgeIcon()}
                        data-speech={speechFormat({
                            key: "Alt + 1",
                            action: "",
                            item: "Select the Company",
                        })}
                    />

                    <InputField
                        width={"420"}
                        readonly={true}
                        disabled={false}
                        onClick={() => selectedPharma && openModal("rfp_file")}
                        onChangefunction={() => selectedPharma && openModal("rfp_file")}
                        rightIcon={DownIcon()}
                        inputCommand={{
                            text: "[Alt + 2]",
                            key: "2",
                            subkey: ["altKey"],
                        }}
                        value={selectedPDFDoc}
                        labelStyle={{
                            position: "absolute",
                            padding: "4px 50px",
                        }}
                        label={"Select the Input"}
                        inputStyle={{
                            paddingBottom: "10px",
                            height: "34px",
                            cursor: selectedPharma ? "" : "not-allowed",
                        }}
                        icon={SelectIcfIcon()}
                        data-speech={speechFormat({
                            key: "Alt + 2",
                            action: "",
                            item: "Select the Company",
                        })}
                    />
                </FormBody>

                <FormFooter>
                    {!loading ? (
                        <Button
                            disabled={(inputDocument || selectedPDFDoc) ? false : true}
                            text={"Generate Proposal"}
                            type={"brand"}
                            accessKeyText={"[Alt + G]"}
                            onClick={handleSubmit}
                            data-speech={speechFormat({
                                key: "Alt + G",
                                action: "generate",
                                item: "Doc",
                            })}
                        />
                    ) : (
                        <Button text={"Generating..."} type={"secondary"} disabled />
                    )}
                </FormFooter>
            </FormContainer>}

            {modalOpen && (
                <ModalWrapper
                    isOpen={modalOpen}
                    onClose={closeModal}
                    overlayName={templateType}
                >
                    <ProtocolModal
                        overlayName={templateType}
                        onClose={closeModal}
                        templateType={templateType}
                        enablePreview={false}
                        title={
                            templateType === "select_pharmacy" 
                            ? "Select the company" 
                            : "Select the Input"
                        }
                        setSelectedInputFile={handleInputFile}
                        selectDocFile={selectDocFile}
                        acceptedFileType={".docx"}
                        pharmaName={selectedPharma}
                    />
                </ModalWrapper>)}

            {loading && (
                <SuccessPopup
                    msg_processing={"Generating Doc, Please wait..."}
                    onClose={() => { }}
                    loading={true}
                />
            )}
        </Wrapper>
    )
}


export default GenerateProposal;


