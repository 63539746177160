import React, { useEffect, useState } from "react";
import {
  ModalWrapper,
  HeaderWrapper,
  HeadTitle,
  HeadRight,
  Title,
  ExtraWrapper,
  ErrorWrapper,
} from "./AddTaskModal.styled";
import Button from "../../../components/Button/Button";
import InputField from "../../../components/InputField/InputField";
import { useDispatch } from "react-redux";
import DropDownNew from "../../../components/Dropdown/Dropdown";
import CompanyBadgeIcon from "../../../assets/svgs/CompanyBadgeIcon";
import CompanyAddressIcon from "../../../assets/svgs/CompanyAddressIcon";
import useKeyPress from "../../../hooks/useKeyPress";
import speechFormat from "../../../helpers/speechFormat";
import DateSelector from "../../../components/DateSelector/DateSelector";
import EntitlementIcon from "../../../assets/svgs/EntitlementIcon";
import SearchAutocomplete from "../../../components/AutoCompleteSearch/AutoCompleteSearch";
import { MemberDetailWrapper } from "../../UpdateClientModal/UpdateClientModal.styled";
import UserIcon from "../../../assets/svgs/UserIcon";
import Emailcon from "../../../assets/svgs/Emailcon";
import validateEmail from "../../../helpers/validateEmail";
import DocumentIcon from "../../../assets/svgs/DocumentIcon";
import { useLocation } from "react-router-dom";
import { createTask } from "../../../apis/ApiServerRequests";
import { incrTaskCount } from "../../../store/slices/TaskSlice";
import Autocomplete from "../../../components/Autocomplete/Autocomplete";
import {useSelector} from "react-redux";


const AddTaskModal = ({ onClose, onSuccess, overlayName }) => {
  const dispatch = useDispatch();
  const [taskName, setTaskName] = useState("");
  const [dueDate, setDueDate] = useState(new Date());
  const [userDetail, setUserDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const location = useLocation();
  const { project_id } = location.state || {};
  
  const task_list = useSelector((state) => state?.task?.task_list);

  const [parentTaskList, setParentTaskList] = useState([]);
  const [selectedTask, setSelectedTask] = useState({})

  const handleInputChange = (e, callback) => {
    callback(e.target.value);
  };

  const handleValidation = () => {
    let valid = true;

    if (!taskName) {
      valid = false;
    }

    if (userDetail.length < 1) valid = false

    userDetail.forEach((e) => {
      let { name, email, role } = e;
      if (!(name && validateEmail(email) && role)) valid = false;
    });

    if (emailError) {
      valid = false;
    }

    return valid;
  };


  useEffect(()=> {
    if (task_list && !parentTaskList.length ) {
      setParentTaskList(task_list.map((item, index) => ({...item, id: index, name: item["title"]})))
    }
  }, [task_list])

  const handleSelectTask = (task) => {
    let foundTask = parentTaskList.find((item) => item.name === task)
    setSelectedTask(foundTask);
  };

  const handleAddTask = () => {
    if (!handleValidation()) return;

    let data = {
      project_id: project_id,
      title: taskName,
      assigned_to: userDetail[0].email,
      due_date: dueDate,
      parent_task: selectedTask["task_id"],
      top_level_task: selectedTask["task_id"]
    };

    console.log("task payload ==>", data)
    
    createTask(data)
    .then(() => {
      setLoading(true);
      onSuccess({ loading: true });
      dispatch(incrTaskCount(1))
    })
    .catch((err) => {
      console.log("error ==>", err)
    })
    .finally(() => {
      setLoading(false)
      onSuccess({ loading: false });
    })

  };


  const assignUser = (value) => {
    if (value?.email && value?.name) {
      setUserDetail([{ name: value?.name, email: value?.email, role: "owner", showOptions: false }]);
    } else if (value?.email) {
      setUserDetail([{ email: value?.email, role: "owner", showOptions: false }]);
    } else if (value?.name) {
      setUserDetail([{ name: value?.name, role: "owner", showOptions: false }]);
    }
  }


  const handleUserInputChange = (e, index, type) => {
    if (type === "email") {
      let data = [...userDetail];
      data[index] = {
        ...data[index],
        email: e.target.value,
        showOptions: true,
      };
      setUserDetail(data);
    }

    if (type === "name") {
      let data = [...userDetail];
      data[index] = { ...data[index], name: e.target.value };
      setUserDetail(data);
    }

  };


  useKeyPress("Escape", [], onClose, true, overlayName, true);

  useKeyPress("Enter", [], handleAddTask, true, overlayName, true);

  return (
    <ModalWrapper>
      <HeaderWrapper>
        <HeadTitle>{"TASK CREATION"}</HeadTitle>
        <HeadRight>
          <Button
            type={"ghost"}
            text={"Cancel"}
            onClick={onClose}
            accessKeyText={"[Esc]"}
            data-speech={speechFormat({
              key: "Escape",
              action: "close",
              item: "Task Creation Dialog",
            })}
          />
          <Button
            type={"secondary"}
            text={loading ? "Saving..." : "Create"}
            onClick={handleAddTask}
            accessKeyText={"[Enter]"}
            disabled={!handleValidation() || loading}
            data-speech={speechFormat({
              key: "Enter",
              action: "create",
              item: "New Task",
            })}
          />
        </HeadRight>
      </HeaderWrapper>
      <div
        style={{
          height: "100%",
          width: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
          paddingBottom: "100px",
        }}
      >
        <InputField
          focus={true}
          label={"Enter Task Name"}
          labelStyle={{
            position: "absolute",
            padding: "4px 50px",
          }}
          inputStyle={{ paddingBottom: "10px", height: "34px" }}
          width={"584"}
          type={"text"}
          value={taskName}
          onChangefunction={(e) => handleInputChange(e, setTaskName)}
          icon={DocumentIcon()}
        />
        <ExtraWrapper>
          <Title>Parent Task (*Optional)</Title>
          <div style={{ height: "52px" }}>
            <Autocomplete
              items={parentTaskList}
              placeholder={"Search Task to Add"}
              onSelected={handleSelectTask} 
            />
          </div>
        </ExtraWrapper>
        <ExtraWrapper>
          <Title>Assigned To</Title>
          <div style={{ width: 584, gap: "20px", display: 'flex', flexDirection: 'column' }}>
            <SearchAutocomplete
              placeholder={"Search User to Assign"}
              autoFocus={true}
              handleAddMember={assignUser}
              searchCommand={{
                text: "[Alt+S]",
                key: "S",
                subkey: ["altKey"],
              }}
              isOverlay={true}
              overlayName={overlayName}
            />
            {userDetail.map((item, index) => {
              return (
                <MemberDetailWrapper
                  bottomPadding={
                    index == userDetail.length - 1 ? "35" : false
                  }
                  style={{
                    paddingTop: index == 0 ? "0px" : "13px",
                    borderBottom:
                      index == userDetail.length - 1
                        ? "none"
                        : "1px solid #f2f2f2",
                  }}
                >
                  <>
                    <InputField
                      width={"280"}
                      labelStyle={{
                        position: "absolute",
                        padding: "4px 50px",
                      }}
                      label={`Enter User's Name`}
                      value={item.name}
                      type={"text"}
                      inputStyle={{
                        paddingBottom: "10px",
                        height: "34px"
                      }}
                      onChangefunction={(e) =>
                        handleUserInputChange(e, index, "name")
                      }
                      icon={UserIcon()}
                    />
                    <InputField
                      width={"280"}
                      labelStyle={{
                        position: "absolute",
                        padding: "4px 50px",
                      }}
                      label={`Enter User's Email`}
                      value={item.email}
                      type={"email"}
                      setError={setEmailError}
                      inputStyle={{
                        paddingBottom: "10px",
                        height: "34px"
                      }}
                      onChangefunction={(e) =>
                        handleUserInputChange(e, index, "email")
                      }
                      icon={Emailcon("black")}
                    />
                  </>
                </MemberDetailWrapper>
              );
            })}
          </div>
        </ExtraWrapper>
        <ExtraWrapper>
          <Title>Select Submission Date</Title>
          <DateSelector setValue={setDueDate} value={dueDate} />
        </ExtraWrapper>
      </div>
    </ModalWrapper>
  );
};

export default AddTaskModal;
