import React, { useState } from "react";
import {
  ListWrapper,
  ListOptionsWrapper,
  ListTitle,
  ListTitleIcon
} from "./TaskManagementPage.styled";
import Search from "../../components/Search/Search";
import Button from "../../components/Button/Button";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
// import ProjectListTable from "../ProjectListTable/ProjectListTable";
// import SuccessPopup from "../../components/SuccesPopup/SuccessPopup";
import useKeyPress from "../../hooks/useKeyPress";
import speechFormat from "../../helpers/speechFormat";
import TitleComponent from "../../components/TitleComponent/TitleComponent";
import { useNavigate, useParams } from "react-router-dom";
import TaskListTable from "../../modules/TaskManagement/TaskListTable/TaskListTable";
import AddTaskModal from "../../modules/TaskManagement/AddTaskModal/AddTaskModal";
import SuccessPopup from "../../components/SuccesPopup/SuccessPopup";
import EstimateProjectModal from "../../modules/ProjectManagement/EstimateProjectModal/EstimateProjectModal";

const TaskManagementPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [input, setInput] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [loadingSuccessPopup, setLoadingSuccessPopup] = useState(false);
  const { projectId } = useParams();
  const navigate = useNavigate();

  const closeModal = () => {
    setModalOpen(false);
    setUpdateModalOpen(false);
  };

  const fetchInput = (val) => {
    setInput(val);
  };

  const handleSuccess = ({ loading }) => {
    setShowSuccessPopup(true);
    setLoadingSuccessPopup(loading);
    closeModal();
  };

  const handleUpdateProjectSuccess = ({ loading }) => {
    setShowUpdatePopup(true);
    setLoadingSuccessPopup(loading);
    closeModal();
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.slice(0, maxLength)}...`;
    }
    return text;
  };

  const overlayName = "AddTaskModal";
  useKeyPress("T", ["altKey"], () => setModalOpen(true));
  useKeyPress("P", ["altKey"], () => setUpdateModalOpen(true));
  useKeyPress("Escape", [], closeModal, true, overlayName);

  return (
    <>
      <ListWrapper>
        <div style={{ display: "flex", cursor: "pointer" }} onClick={() => navigate("/project")}>
            <ListTitleIcon>{`Regulatory PM > ${truncateText(projectId, 19)}`}</ListTitleIcon>
            <ListTitle length={`Regulatory PM > ${projectId}`.length}></ListTitle>
          <TitleComponent />
        </div>
        <ListOptionsWrapper>
          <Search
            width={400}
            thres={1300}
            placeholdertext={"Search tasks..."}
            righticons={true}
            onChange={fetchInput}
            value={input}
            searchCommand={{
              text: "[G]",
              key: "G",
              subkey: [],
            }}
            voiceCommand={{
              text: "[Alt+G]",
              key: "G",
              subkey: ["altKey"],
            }}
            speech={speechFormat({
              action: "search",
              item: "projects",
            })}
          />
          <Button
            type="brand"
            text="+ Add Task"
            accessKeyText="[ Alt + T ]"
            onClick={() => setModalOpen(true)}
            buttonStyle={{ marginLeft: 10 }}
            data-speech={speechFormat({
              key: "Alt + T",
              action: "",
              item: "Add Task",
            })}
          />
          <Button
            type="brand"
            text="Update Project Timeline"
            accessKeyText="[ Alt + P ]"
            onClick={() => setUpdateModalOpen(true)}
            buttonStyle={{ marginLeft: 10 }}
            data-speech={speechFormat({
              key: "Alt + P",
              action: "",
              item: "Update Timeline",
            })}
          />
        </ListOptionsWrapper>
      </ListWrapper>
      {modalOpen && (
        <ModalWrapper
          isOpen={modalOpen}
          onClose={closeModal}
          overlayName={overlayName}
        >
          <AddTaskModal
            onClose={closeModal}
            onSuccess={handleSuccess}
            overlayName={overlayName}
          />
        </ModalWrapper>
      )}

      {
        updateModalOpen && (
          <ModalWrapper
            isOpen={updateModalOpen}
            onClose={closeModal}
            overlayName={overlayName}
          >
            <EstimateProjectModal
              onClose={closeModal}
              onSuccess={handleUpdateProjectSuccess}
              overlayName={overlayName}
            />
          </ModalWrapper>
        )
      }
      
      <TaskListTable searchInput={input} setSearchInput={setInput} />
      {showSuccessPopup && (
        <SuccessPopup
          msg_processing={"Adding a new task, please wait..."}
          msg_done={"Task is added successfully."}
          onClose={() => setShowSuccessPopup(false)}
          loading={loadingSuccessPopup}
        />
      )}

      {showUpdatePopup && (
        <SuccessPopup
          msg_processing={"Updating project, please wait..."}
          msg_done={"Project updated successfully."}
          onClose={() => setShowUpdatePopup(false)}
          loading={loadingSuccessPopup}
        />
      )}
    </>
  );
};

TaskManagementPage.propTypes = {};

TaskManagementPage.defaultProps = {};

export default TaskManagementPage;
