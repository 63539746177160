import { useEffect, useState } from "react";
import { DocumentEditor } from "@onlyoffice/document-editor-react";
import BackspaceIcon from "../../assets/svgs/BackspaceIcon";
import {
    MainWrapper,
    BackspaceContainer,
    HeaderWrapper,
    HeaderTitleWrapper,
    HeaderTitle,
    EditorWrapper,
    DocWrapper
} from "./RFPGenerationPage.styled";
import speechFormat from "../../helpers/speechFormat";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import SuccessICF from "../../modules/SuccessICF/SuccessICF";
import SuccessPopup from "../../components/SuccesPopup/SuccessPopup";
import { ProcessProposalDocument } from "../../apis/ApiServerRequests";

const RFPGenerationPage = () => {
    const DEFAULT_URL = "https://dms-onlyoffice.agilewriter.smarter.codes";
    const DEFAULT_RFPSERVER_URL = "https://rfp.localhost";
    const ONLYOFFICE_URL = process.env.REACT_APP_ONLYOFFICE_URL
        ? process.env.REACT_APP_ONLYOFFICE_URL
        : DEFAULT_URL;
    const RFPSERVER_URL = process.env.REACT_APP_RFP_SERVER_URL
        ? process.env.REACT_APP_RFP_SERVER_URL
        : DEFAULT_RFPSERVER_URL;
    const navigate = useNavigate();
    const location = useLocation();
    const { data } = location.state || {};
    const [fileData, setFileData] = useState(null);

    const [loading, setLoading] = useState(false);
    const [generated, setGenerated] = useState(false);
    const [isEditorFullSize, setIsEditorFullSize] = useState(true);
    const [isUploaded, setIsUploaded] = useState(false);

    const [progress, setProgress] = useState({
        total_mappings: 21,
        mappings_processed: 21,
        estimated_time: 5,
    });

    const [divHeight, setDivHeight] = useState(0);
    const [companyData, setCompanyData] = useState(null);

    useEffect(() => {
        const calculateHeight = () => {
        const availableHeight = window.innerHeight; // Total window height
        const otherElementsHeight = 100; // Example: height of headers or other components
        const calculatedHeight = availableHeight - otherElementsHeight;

        setDivHeight(calculatedHeight);
        };

        // Calculate height on component mount and resize
        calculateHeight();
        window.addEventListener('resize', calculateHeight);

        // Cleanup event listener on component unmount
        return () => {
        window.removeEventListener('resize', calculateHeight);
        };
    }, []);

    var onDocumentReady = function (event) {
        console.log("Document is loaded");
    };

    var onLoadComponentError = function (errorCode, errorDescription) {
        switch (errorCode) {
            case -1: // Unknown error loading component
                console.log(errorCode, errorDescription);
                break;

            case -2: // Error load DocsAPI from http://documentserver/
                console.log(errorCode, errorDescription);
                break;

            case -3: // DocsAPI is not defined
                console.log(errorCode, errorDescription);
                break;
        }
    };


    useEffect(() => {
        if (!companyData) {
            setCompanyData(data)
        }

    }, [data])


    useEffect(() => {
        if (companyData && !loading) {
            setLoading(true);
            const getDocumentfunction = (data) => {
                ProcessProposalDocument(data.pharma_name, data.input_document_name)
                .then(({ data }) => {
                    // console.log("set FIle Data ==>", data)
                    setFileData(data)
                    setLoading(false);
                })
                .catch(() => {
                    alert("Something went wrong. Try again later.");
                });
            } 
            getDocumentfunction(companyData)
        }
    }, [companyData])


    const onBack = () => {
        navigate("/");
    };


    const handleGenerated = (val) => {
        navigate('/proposal')
    };

    const saveDocument = () => {
        setLoading(true)
        const event = new KeyboardEvent('keydown', {
            bubbles: true,
            cancelable: true,
            key: 's',
            ctrlKey: true,
        });

        document.dispatchEvent(event);
        setTimeout(() => {
            setLoading(false)
            setIsUploaded(true);
        }, 12000);
    };

    return (
        <MainWrapper>
            {loading && !generated && (
                <SuccessPopup
                    msg_processing={"Generating Doc, Please wait..."}
                    onClose={() => { }}
                    loading={true}
                    styled={false}
                />
            )
            }

            {
                isUploaded && !loading  && <SuccessICF
                    handleGenerated={handleGenerated}
                    docTitle={fileData?.filename}
                    totalMapping={progress?.total_mappings}
                    resolvedMapping={progress?.mappings_processed}
                ></SuccessICF>
            }

            {!isUploaded && <HeaderWrapper>
                <BackspaceContainer>
                    <Button
                        icon={<BackspaceIcon />}
                        type={"ghost"}
                        accessKeyText={"[BACKSPACE]"}
                        onClick={onBack}
                        iconStyle={{ marginRight: "4px" }}
                        data-speech={speechFormat({
                            key: "Backspace",
                            action: "go back",
                            item: "to Dashboard",
                        })}
                        buttonStyle={{ paddingTop: "0px" }}
                    />

                </BackspaceContainer>
                <HeaderTitleWrapper>
                    <HeaderTitle>REVIEW PROPOSAL</HeaderTitle>
                    <div style={{ display: 'flex', marginRight: "28px" }}>
                        <Button
                            buttonStyle={{ marginLeft: "6px", marginRight: "10px" }}
                            text={"Create Final Doc"}
                            type={"tertiary"}
                            accessKeyText="[G]"
                            onClick={() => saveDocument()}
                            data-speech={speechFormat({
                                key: "G",
                                action: "",
                                item: "Generate Doc",
                            })}
                        />
                    </div>
                </HeaderTitleWrapper>

            </HeaderWrapper>}
            {!isUploaded && 
                <EditorWrapper >
                    <DocWrapper sideAlign={isEditorFullSize}>
                        {fileData && <DocumentEditor
                            id="docxEditor"
                            documentServerUrl={ONLYOFFICE_URL}
                            config={{
                                "document": {
                                    "fileType": "docx",
                                    "info": {},
                                    "key": "wehfsf345",
                                    "permissions": {},
                                    "title": fileData?.filename,
                                    "url": fileData?.public_url
                                },
                                "documentType": "word",
                                "editorConfig": {
                                    "callbackUrl": "https://api-dpai.agilewriter.smarter.codes/callback?file=something",
                                    "customization": {
                                        "anonymous": {
                                            "request": false,
                                            "label": "Agilewriter"
                                        },
                                        "autosave": true,
                                        'search': false,
                                        'chat': false,
                                        'headings': false,
                                        'about': false,
                                        'reviewDisplay': false,
                                        "toolbar": true,
                                        "comments": true,
                                        "compactHeader": true,
                                        "compactToolbar": true,
                                        "compatibleFeatures": false,
                                        "forcesave": true,
                                        "help": false,
                                        "hideRightMenu": true,
                                        "hideRulers": true,
                                        "integrationMode": "embed",
                                        "macros": false,
                                        "macrosMode": "Warn",
                                        "mentionShare": false,
                                        "mobileForceView": true,
                                        "plugins": true,
                                        "toolbarHideFileName": false,
                                        "toolbarNoTabs": true,
                                        "uiTheme": "theme-light",
                                        "unit": "cm",
                                        "zoom": 100
                                    }
                                },
                                "height": !isEditorFullSize ? `${(divHeight/2) - 20}px` : `${divHeight- 40}px`,
                                "token": fileData?.token,
                                "width": "100%",
                                "type": "desktop"
                            }
                            }
                            events_onDocumentReady={onDocumentReady}
                            onLoadComponentError={onLoadComponentError}
                        />}
                    </DocWrapper>
                </EditorWrapper>
            }
        </MainWrapper>
    )

}


export default RFPGenerationPage;